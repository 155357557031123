import imagesLoaded from "imagesloaded";

export const typewriter = (
  el,
  str,
  speed = 50,
  increment = 2,
  theLetters = "abcdefghijklmnopqrstuvwxyz"
) => {
  let clen = str.length;
  let si = 0;
  let stri = 0;
  let block = "";
  let fixed = "";
  (function rustle(i) {
    setTimeout(function () {
      if (--i) {
        rustle(i);
      }
      nextFrame(i);
      si = si + 1;
    }, speed);
  })(clen * increment + 1);

  function nextFrame(pos) {
    for (let i = 0; i < clen - stri; i++) {
      let num = Math.floor(theLetters.length * Math.random());
      let letter = theLetters.charAt(num);
      // block = block + letter;
      block = letter;
    }
    if (si === increment - 1) {
      stri++;
    }
    if (si === increment) {
      fixed = fixed + str.charAt(stri - 1);
      si = 0;
    }
    el.innerText = fixed + block;
    block = "";
  }
};

export const typewriter2 = (
  el,
  str,
  speed = 30,
  iteration = 0,
  letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  let interval = null;

  clearInterval(interval);

  interval = setInterval(() => {
    el.innerText = el.innerText
      .split("")
      .map((letter, index) => {
        if (index < iteration) {
          return str[index];
        }
        return letters[Math.floor(Math.random() * 26)];
      })
      .join("");

    if (iteration >= str.length) {
      clearInterval(interval);
    }
    iteration += 1 / 3;
  }, speed);
};

// const populateStructure = {
//   Investor: {
//     tags: true,
//     rarity: true,
//   },
//   Clients: {
//     tags: true,
//     rarity: true,
//   },
// };
// 这里的 populate 是关键字, [0] 是数组索引, 代表参数的个数, Investor 是对象名, 这个写法真 TM 操蛋,那个神经病设计的
//  "http://localhost:1337/api/investor-cards?populate[0]=Investor&populate[1]=Investor.tags&populate[2]=Investor.rarity"

// 用于生成strapi及其操蛋的populate参数
export function buildPopulateUrl(url, populateStructure) {
  let index = 0;

  const buildParams = (obj) => {
    return Object.entries(obj).flatMap(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return [`populate[${index++}]=${key}`].concat(
          Object.entries(value).map(
            ([subKey, subValue]) => `populate[${index++}]=${key}.${subKey}`
          )
        );
      } else {
        return [`populate[${index++}]=${key}`];
      }
    });
  };

  const params = buildParams(populateStructure);
  return `${process.env.REACT_APP_API_URL + url}?${params.join("&")}`;
}

// const populateStructure = {
//   To_C: ["reflection", "keywords"],
//   To_B: ["reflection", "keywords"],
//   To_G: ["reflection", "keywords"],
// };

// 用于生成strapi复杂嵌套populate参数的函数

// http://localhost:1337/api/home-card?populate[To_C][populate][0]=reflection&populate[To_C][populate][1]=keywords&populate[To_B][populate][2]=reflection&&populate[To_B][populate][3]=keywords
// 用于生成strapi复杂嵌套populate参数的函数
export function buildComplexPopulateUrl(url, populateStructure) {
  const buildParams = (obj, prefix = "") => {
    return Object.entries(obj).flatMap(([key, value]) => {
      const currentKey = prefix ? `${prefix}[${key}]` : key;

      if (Array.isArray(value)) {
        return value.map(
          (subKey, index) =>
            `populate[${currentKey}][populate][${index}]=${subKey}`
        );
      } else if (typeof value === "object" && value !== null) {
        return buildParams(value, `${currentKey}`);
      } else {
        return [`populate[${currentKey}]=${value}`];
      }
    });
  };

  const params = buildParams(populateStructure);
  return `${process.env.REACT_APP_API_URL + url}?${params.join("&")}`;
}
