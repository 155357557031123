import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import AnimationLine from "../../components/AnimationLine";
import AnimationText from "../../components/AnimationText.jsx";
import Button from "../../components/Button.jsx";
import BackButton from "../../components/BackButton.jsx";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useLabel,
  useDetail,
  useActiveCard,
  useHoverCard,
  useLoading,
  useKeyword,
} from "../../store";
import styles from "./index.module.scss";

function Home({ t, jump }) {
  const loading = useLoading((state) => state.loading);

  const activeCard = useActiveCard((state) => state.activeCard);
  const setActiveCard = useActiveCard((state) => state.setActiveCard);
  const setHoverCard = useHoverCard((state) => state.setHoverCard);
  const location = useLocation();

  return (
    <div
      className="fixed items-center h100 top-[48px] lg:top-[66px]"
      style={{ zIndex: 53, width: activeCard ? "100%" : "0%" }}
    >
      {activeCard && location.pathname === "/item" ? (
        <ItemDetail
          active={activeCard}
          onActive={setActiveCard}
          onHover={setHoverCard}
          jump={jump}
          t={t}
        />
      ) : (
        <Tag t={t}></Tag>
      )}
      {location.pathname === "/" && !loading && <Slogan t={t} />}
    </div>
  );
}

// 项目详情
function ItemDetail({ active, onActive, onHover, jump, t }) {
  const itemRef = useRef(null);
  const iframeRef = useRef(null);
  const [detailData, setDetailData] = useState({});
  const { i18n } = useTranslation();
  const detail = useDetail((state) => state.detail);
  const keywords = useKeyword((state) => state.keywordList);
  const labels = useLabel((state) => state.labelList);
  console.log(keywords, "detail");
  useEffect(() => {
    if (active) {
      // let detailList = [];
      // if (i18n.language === "zh") {
      //   detailList = detail.zh;
      // } else {
      //   detailList = detail.en;
      // }
      setDetailData(detail.find((item) => item.id === active.id));
      console.log(
        detail.find((item) => item.id === active.id),
        "detail"
      );
      gsap.to(itemRef.current, {
        opacity: 1,
        duration: 0.2,
        delay: 0.1,
        onStart: () => {
          itemRef.current.style.display = "flex";
        },
      });
    }
    // else {
    //   // 当组件卸载时，创建移出动画
    //   gsap.to(itemRef.current, {
    //     opacity: 0,
    //     onComplete: () => {
    //       if (itemRef.current) {
    //         itemRef.current.style.display = "none";
    //       }
    //     },
    //   });
    // }
  }, [active, i18n.language]);
  return (
    <div>
      {window.isSmallDevice ? (
        <div
          style={{
            zIndex: 51,
            opacity: 0,
          }}
          className="flex h100 flex-col w-full  justify-between absolute top-0 left-0 bg-black transform"
          ref={itemRef}
        >
          {/* 卡片 */}
          <iframe
            title="cardItem"
            className="w-full h-[350px]"
            style={{ minHeight: "350px" }}
            allow="accelerometer"
            src={`${window.iframeOrigin}/card/?card_list=[${active.id}]&card_type=home&card_size=200&card_animation=${active.id}`}
          ></iframe>
          {/* 名称 */}
          <div
            className="relative"
            style={{
              height: "calc(100svh - 398px)",
            }}
          >
            <div
              style={{
                height: "calc(100svh - 436px)",
              }}
              className="flex w-full flex-col bg-black relative"
            >
              <AnimationLine
                classList="line-t border-t h-[1px]"
                animation={{
                  duration: 2,
                  height: "100%",
                  ease: "power1.inOut",
                }}
              />
              <h1 className="w-full px-4 py-1 relative fontBold">
                <AnimationLine
                  classList="line-t border-t w-full h-[1px]"
                  animation={{
                    duration: 3,
                    width: "100%",
                    ease: "power3.out",
                  }}
                />
                {detailData.title_zh && (
                  <div className="text-xl w-fit text-nowrap overflow-x-scroll">
                    {i18n.language === "zh"
                      ? detailData.title_zh
                      : detailData.title_en}
                  </div>
                )}
                <AnimationLine
                  classList="line-b border-b w-full h-[1px]"
                  animation={{
                    duration: 3,
                    width: "100%",
                    ease: "power3.out",
                  }}
                />
              </h1>
              {detailData.keywords && (
                <div className="flex flex-wrap justify-between content-between  px-4 py-1 w-full relative">
                  <AnimationLine
                    classList="line-b border-b w-full h-[1px] z-50"
                    animation={{
                      duration: 3,
                      width: "100%",
                      ease: "power3.out",
                    }}
                  />
                  {detailData.keywords.map((item, index) => (
                    <span
                      className="text-xs text-center w-fit  pr-2"
                      key={index}
                    >
                      {i18n.language === "zh"
                        ? keywords.find((i) => i.id === item.term_id).key_zh
                        : keywords.find((i) => i.id === item.term_id).key_en}
                    </span>
                  ))}
                </div>
              )}

              {detailData.labels && (
                <div className="flex flex-wrap justify-between content-between px-4 py-1 w-full relative ">
                  <AnimationLine
                    classList="line-b border-b w-full h-[1px] z-50"
                    animation={{
                      duration: 3,
                      width: "100%",
                      ease: "power3.out",
                    }}
                  />
                  {detailData.labels.map((item, index) => (
                    <span
                      className="text-xs w-fit text-center  pr-4"
                      key={index}
                    >
                      {i18n.language === "zh"
                        ? labels.find((i) => i.id === item).label_zh
                        : labels.find((i) => i.id === item).label_en}
                    </span>
                  ))}
                </div>
              )}
              <div className="px-4 w-full py-2 overflow-scroll flex flex-col justify-between items-center">
                <div className="w-full text-xs lg:leading-8 text-pretty whitespace-pre-wrap">
                  {i18n.language === "zh"
                    ? detailData.introduce_zh
                    : detailData.introduce_en}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-evenly items-center absolute bottom-0 bg-black">
              <Button
                text="BACK"
                onClick={() => {
                  onActive(null);
                }}
              ></Button>
              <Button
                text="LEARN  MORE"
                onClick={() => {
                  jump("/detail", { id: detailData.link });
                }}
              ></Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={itemRef}
          style={{ zIndex: 52 }}
          className="w-full h-full bg-black text-white absolute"
        >
          <div
            style={{
              maxWidth: "35%",
              minWidth: "550px",
              width: "35%",
            }}
            className="flex  relative bg-black flex-col justify-between items-center h-full"
          >
            <iframe
              ref={iframeRef}
              title="cardItem"
              className="w-full h-full overflow-hidden"
              allow="accelerometer"
              src={`${window.iframeOrigin}/card/?card_list=[${active.id}]&card_type=home&card_align=center&card_size=450&card_animation=${active.id}`}
              // src={`http://192.168.50.191:5173/?card_list=[${active.id}]&card_align=center&card_size=450&card_animation=${active.id}`}
            ></iframe>
          </div>
          {/* 详情图文介绍 */}
          <div
            className="px-16 flex flex-col items-center justify-center"
            style={{
              maxWidth: "75%",
              minWidth: "550px",
              width: "75%",
            }}
          >
            <div className="flex border w-full flex-row bg-black relative flex-wrap justify-start content-start h-[650px]">
              <h1 className="w-full h-24 relative flex items-center fontBold">
                {detailData.title_zh && (
                  <div className="text-4xl text-center w-full">
                    {i18n.language === "zh"
                      ? detailData.title_zh
                      : detailData.title_en}
                  </div>
                )}
                <AnimationLine
                  classList="line-b border-b w-full h-[1px]"
                  animation={{
                    duration: 3,
                    width: "100%",
                    ease: "power3.out",
                  }}
                />
              </h1>
              <div
                className="w-full flex relative"
                style={{ height: "calc(650px - 6rem)" }}
              >
                <AnimationLine
                  classList="absolute top-16 border-b h-[1px]"
                  animation={{
                    duration: 3,
                    width: "100%",
                    ease: "power3.out",
                  }}
                />
                <AnimationLine
                  classList="absolute right-0 top-32 border-t  h-[1px]"
                  animation={{
                    duration: 3,
                    width: "100%",
                    ease: "power3.out",
                  }}
                />
                <AnimationLine
                  classList="absolute top-0 left-[20%] border-r w-[1px]"
                  animation={{
                    duration: 4,
                    height: "100%",
                    ease: "power3.out",
                  }}
                />
                <div className="w-[20%] h-full flex flex-col relative text-white text-sm">
                  <div className="w-full flex justify-center items-center h-16 text-center">
                    {t("关键字")}
                  </div>
                  <div className="w-full flex justify-center items-center h-16 text-center">
                    {t("标签")}
                  </div>
                  <div className="w-full flex justify-center flex-1 items-center text-center">
                    {t("内容")}
                  </div>
                </div>
                <div className="w-[80%]">
                  {detailData.keywords && (
                    <div className="w-full flex p-4 justify-evenly items-center h-16 text-center relative">
                      {detailData.keywords.map((item, index) => (
                        <div className="text-sm text-center" key={index}>
                          {i18n.language === "zh"
                            ? keywords.find((i) => i.id === item.term_id).key_zh
                            : keywords.find((i) => i.id === item.term_id)
                                .key_en}
                        </div>
                      ))}
                    </div>
                  )}
                  {detailData.labels && (
                    <div className="flex w-full h-16 p-4 justify-evenly items-center relative">
                      {detailData.labels.map((item, index) => (
                        <div className="text-sm  text-center pr-2" key={index}>
                          {i18n.language === "zh"
                            ? labels.find((i) => i.id === item).label_zh
                            : labels.find((i) => i.id === item).label_en}
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    className="flex w-full flex-1"
                    style={{ height: "calc(650px - 14rem)" }}
                  >
                    <div className="p-6 h-full w-full  flex flex-col justify-between items-center">
                      <div className="w-full flex-1  overflow-y-scroll leading-8 text-pretty whitespace-pre-wrap">
                        {i18n.language === "zh"
                          ? detailData.introduce_zh
                          : detailData.introduce_en}
                      </div>
                      <div className="flex w-full justify-between">
                        <BackButton
                          text={t("返回")}
                          onClick={() => {
                            onActive(null);
                            onHover(null);
                          }}
                        ></BackButton>
                        <Button
                          text={t("查看更多")}
                          onClick={() => {
                            jump("/detail", { id: detailData.link });
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// 标语
function Slogan({ t }) {
  const sloganRef = useRef();
  const sloganBoxRef = useRef();
  const { i18n } = useTranslation();
  // const textRef = useRef([]);

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     const x = (event.clientX / window.innerWidth - 0.5) * 20;
  //     const y = (event.clientY / window.innerHeight - 0.5) * 20;

  //     sloganRef.current.style.transform = `translateX(${x}px) translateY(${y}px)`;
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);
  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);
  // const addToRefs = (el) => {
  //   if (el && !sloganRef.current.includes(el)) {
  //     sloganRef.current.push(el);
  //   }
  // };

  useEffect(() => {
    gsap.from(sloganBoxRef.current, {
      opacity: 0,
      y: -50,
      duration: 1,
      delay: 2,
      ease: "power3.out",
    });
  }, []);

  // useEffect(() => {
  //   if (!show) {
  //     textRef.current.forEach((char, index) => {
  //       gsap.to(char, {
  //         opacity: 0,
  //         y: 0, // 可选：添加一个轻微的 Y 轴位移
  //         duration: 1,
  //         delay: index * 0.2,
  //         ease: "power2.out",
  //         onComplete: function () {
  //           if (sloganBoxRef.current) {
  //             sloganBoxRef.current.style.display = "none";
  //           }
  //         },
  //       });
  //     });
  //   }
  // }, [show]);

  return (
    <div
      className="fixed w-full h100 select-none pointer-events-none"
      style={{
        zIndex: 54,
      }}
      ref={sloganBoxRef}
    >
      <div
        ref={sloganRef}
        className="absolute top-0 left-0  w-full h-full lg:p-6 "
        style={{
          pointerEvents: "none",
          perspective: "100px",
          transformStyle: "preserve-3d",
          willChange: "transform",
        }}
      >
        {window.isSmallDevice ? (
          <div className="text-4xl mt-4">
            <div className={styles.glitch} data-text={t("手机标语1")}>
              {t("手机标语1")}
            </div>
            <div
              style={{ marginLeft: i18n.language === "zh" ? "0.6rem" : "0" }}
              className={styles.glitch}
              data-text={t("手机标语2")}
            >
              {t("手机标语2")}
            </div>
          </div>
        ) : (
          <div
            className={styles.glitch}
            style={{
              fontSize: "4rem",
              letterSpacing: i18n.language === "zh" ? "20px" : "unset",
            }}
            data-text={t("标语")}
          >
            {t("标语")}
            <img
              src={window.filePath + "home.png"}
              alt="Logo"
              className="hidden lg:block absolute bottom-28 w-20 left-10"
            />
            <img
              src={window.filePath + "home2.png"}
              alt="Logo"
              className="hidden lg:block absolute bottom-10 left-10 w-48"
            />
            <img
              src={window.filePath + "home3.png"}
              alt="Logo"
              style={{
                bottom: "19rem",
              }}
              className="hidden lg:block absolute bottom-80 w-20 right-10"
            />
            <img
              src={window.filePath + "home4.png"}
              alt="Logo"
              className="hidden lg:block absolute bottom-52 right-10 w-20 "
            />
            <img
              src={window.filePath + "home5.png"}
              alt="Logo"
              shader="rgbShift"
              className="hidden lg:block absolute bottom-28 right-10 w-20 "
            />
            <img
              src={window.filePath + "home6.png"}
              alt="Logo"
              className="hidden lg:block absolute bottom-10 right-10 w-60"
            />
          </div>
        )}

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="distort-1">
              <feTurbulence baseFrequency="0.02" numOctaves="8"></feTurbulence>
              <feDisplacementMap
                in="SourceGraphic"
                scale="10"
              ></feDisplacementMap>
            </filter>

            <filter id="distort-2">
              <feTurbulence baseFrequency="0.01" numOctaves="6"></feTurbulence>
              <feDisplacementMap
                in="SourceGraphic"
                scale="10"
              ></feDisplacementMap>
            </filter>

            <filter id="sharp-distort">
              <feTurbulence baseFrequency="0.2" numOctaves="8"></feTurbulence>
              <feConvolveMatrix
                kernelMatrix="
         0  -1   0
        -1   5  -1
         0  -1   0
      "
              ></feConvolveMatrix>
              <feDisplacementMap
                in="SourceGraphic"
                scale="10"
              ></feDisplacementMap>
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}

// 过滤器
function Tag({ t }) {
  const labelList = useLabel((state) => state.labelList);
  const setLabel = useLabel((state) => state.setLabel);
  const label = useLabel((state) => state.label);
  // const detail = useDetail((state) => state.detail);
  const { i18n } = useTranslation();
  const location = useLocation();
  // const [onTopTag, setOnTopTag] = useState(false);
  const [onSubTag, setOnSubTag] = useState(false);
  // const [labelList, setLabelList] = useState([]);
  // const [tagList, setTagList] = useState([]);
  console.log(labelList, "labelList");
  const handleLabel = (item) => {
    console.log(item, "ssda");
    if (item === label) {
      setLabel(false);
    } else {
      setLabel(item);
    }
  };
  const activeCard = useActiveCard((state) => state.activeCard);
  useEffect(() => {
    setLabel(false);
  }, [activeCard]);
  // useEffect(() => {
  //   if (detail.en) {
  //     let detailList = [];
  //     if (i18n.language === "zh") {
  //       detailList = detail.zh;
  //     } else {
  //       detailList = detail.en;
  //     }
  //     // 过滤
  //     let arr = [...new Set(detailList.map((item) => item.label).flat())];
  //     let tags = [...new Set(detailList.map((item) => item.tag).flat())];
  //     tags = tags.sort((a, b) => a.length - b.length);
  //     arr = arr.sort((a, b) => a.length - b.length);
  //     const priorityElements = ["To B", "To G", "To C"]; // 指定要放在前面的元素
  //     const nonPriority = tags.filter(
  //       (item) => !priorityElements.includes(item)
  //     );
  //     // 将优先元素与排序后的非优先元素合并
  //     const result = [...priorityElements, ...nonPriority];
  //     // setLabelList(arr);
  //     setTagList(result);
  //   }
  //   console.log("useEffect", detail);
  // }, [detail, i18n.language]);
  return (
    <div>
      {location.pathname === "/item" && (
        <div>
          <div
            style={{ zIndex: 53 }}
            className="absolute bottom-[1rem] lg:bottom-[2rem] h-auto w-64 overflow-y-hidden left-2 pl-2 lg:pl-6 h-[90%] flex flex-col justify-between "
          >
            <div className="flex w-auto flex-col  justify-evenly h-auto select-none">
              {/* <div
                className="fontBold w-fit overflow-hidden text-sm lg:text-xl tracking-wider transition-all duration-200 ease-in-out"
                style={{
                  height: onTopTag
                    ? `calc(${
                        window.isSmallDevice
                          ? tagList.length * 19
                          : tagList.length * 23
                      }px + 1.2em)`
                    : "1.2em",
                }}
              >
                <div
                  onClick={() => setOnTopTag(!onTopTag)}
                  className="flex item-center cursor-pointer "
                >
                  {t("一级标签")}
                  <i
                    style={{
                      transform: onTopTag ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    className="text-sm lg:text-xl transition-all duration-200 ease-in-out"
                  >
                    &#xe63c;
                  </i>
                </div>
                {tagList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleLabel(item);
                      }}
                      className="cursor-pointer text-nowrap w-fit transition-all duration-200 ease-in-out  fontThin hover:text-neutral-400"
                      style={
                        window.isSmallDevice
                          ? {
                              color: item === label && "rgb(163 163 163)",
                              borderRadius: "0.5rem",
                              padding: "1px",
                              lineHeight: "14px",
                              fontSize: "12px",
                              border:
                                item === label
                                  ? "1px solid rgb(163 163 163)"
                                  : "1px solid transparent",
                            }
                          : {
                              color: item === label && "rgb(163 163 163)",
                              borderRadius: "0.5rem",
                              padding: "1.5px",
                              lineHeight: "17px",
                              fontSize: "14px",
                              border:
                                item === label
                                  ? "1px solid rgb(163 163 163)"
                                  : "1px solid transparent",
                            }
                      }
                    >
                      {item}
                    </div>
                  );
                })}
              </div> */}
              <div
                style={{
                  height: onSubTag
                    ? `calc(${
                        window.isSmallDevice
                          ? labelList.length * 19
                          : labelList.length * 23
                      }px + 1.2em)`
                    : "1.2em",
                }}
                className="fontBold  overflow-hidden  text-sm lg:text-xl mt-[0rem] mb-0 tracking-wider transition-all duration-200 ease-in-out"
              >
                <div
                  onClick={() => setOnSubTag(!onSubTag)}
                  className="flex item-center cursor-pointer "
                >
                  {t("标签")}
                  <i
                    style={{
                      transform: onSubTag ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    className="text-sm lg:text-xl transition-all duration-200 ease-in-out"
                  >
                    &#xe63c;
                  </i>
                </div>
                {labelList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleLabel(item.id);
                      }}
                      className="cursor-pointer  text-nowrap w-fit  transition-all duration-200 ease-in-out fontThin hover:text-neutral-400"
                      style={
                        window.isSmallDevice
                          ? {
                              color: item.id === label && "rgb(163 163 163)",
                              padding: "1px",
                              lineHeight: "14px",
                              fontSize: "12px",
                              borderRadius: "0.5rem",
                              border:
                                item.id === label
                                  ? "1px solid rgb(163 163 163)"
                                  : "1px solid transparent",
                            }
                          : {
                              color: item.id === label && "rgb(163 163 163)",
                              padding: "1.5px",
                              lineHeight: "17px",
                              fontSize: "14px",
                              borderRadius: "0.5rem",
                              border:
                                item.id === label
                                  ? "1px solid rgb(163 163 163)"
                                  : "1px solid transparent",
                            }
                      }
                    >
                      {i18n.language === "zh" ? item.label_zh : item.label_en}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="w-full text-sm grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {labelList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleLabel(item);
                      }}
                      className="cursor-pointer hover:text-neutral-400"
                      style={{
                        color: item === label && "rgb(163 163 163)",
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
