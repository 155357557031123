// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// 导入语言资源文件
import translationEN from "./locales/en/translation.json";
import translationZH from "./locales/zh/translation.json";

// 初始化i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    zh: { translation: translationZH },
  },
  lng: "en", // 默认语言
  fallbackLng: "en", // 如果未找到对应语言，使用默认语言
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
