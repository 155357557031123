import { create } from "zustand";

export const useCardList = create((set) => ({
  cardList: [],
  setCardList: (state) => set({ cardList: state }),
}));

export const useActiveCard = create((set) => ({
  activeCard: null,
  setActiveCard: (state) => set({ activeCard: state }),
}));

export const useHoverCard = create((set) => ({
  hoverCard: null,
  setHoverCard: (state) => set({ hoverCard: state }),
}));

export const useTag = create((set) => ({
  tag: false,
  setTag: (state) => set({ tag: state }),
}));

export const useLabel = create((set) => ({
  label: false,
  setLabel: (state) => set({ label: state }),
  labelList: [],
  setLabelList: (state) => set({ labelList: state }),
}));

export const useLoading = create((set) => ({
  loading: true,
  setLoading: (state) => set({ loading: state }),
}));

export const useDetail = create((set) => ({
  detail: {},
  setDetail: (state) => set({ detail: state }),
}));

export const useKeyword = create((set) => ({
  keywordList: [],
  setKeywordList: (state) => set({ keywordList: state }),
}));
