import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useLoading } from "../store";
import gsap from "gsap";
import "./Loading.css";

const Loading = () => {
  const loaderRef = useRef(null);
  const loading = useLoading((state) => state.loading);
  useEffect(() => {
    if (loading) {
      gsap.to(loaderRef.current, {
        duration: 0.4,
        autoAlpha: 1,
        display: "block",
      });
    } else {
      gsap.to(loaderRef.current, {
        duration: 0.4,
        autoAlpha: 0,
        onComplete: () => {
          loaderRef.current.style.display = "none";
        },
      });
    }
  }, [loading]);
  return (
    <iframe
      ref={loaderRef}
      title="loading"
      className="loadingIframe"
      src={`${window.iframeOrigin}/loading/`}
    ></iframe>
  );
};

export default Loading;
