import { createRoot } from "react-dom/client";
import { useState, useRef, useEffect, useMemo } from "react";
import "./index.css";
import { App } from "./App";
import "./i18n";
import "./icon/iconfont.css";

function Root() {
  console.log(process.env, "process.env.REACT_APP_API_URL");
  // const [zoom, setZoom] = useState(false);
  // const cursorRef = useRef();
  // const cursorOutlineRef = useRef();
  // const handleClick = (e) => {
  //   console.log(11);
  //   setZoom(!zoom);
  // };
  useMemo(() => {
    console.log("useMemo");
    window.filePath = process.env.PUBLIC_URL + "/assets/";
    window.iframeOrigin =
      process.env.NODE_ENV === "development"
        ? "https://oxyz3.xyz"
        : window.location.origin;
  }, []);
  useEffect(() => {
    //   function setCursorPosition(e) {
    //     cursorRef.current.setAttribute(
    //       "style",
    //       "opacity: 1; transform: translate(" +
    //         e.clientX +
    //         "px," +
    //         e.clientY +
    //         "px)"
    //     );
    //     cursorOutlineRef.current.setAttribute(
    //       "style",
    //       "opacity: 1; transform: translate(" +
    //         e.clientX +
    //         "px," +
    //         e.clientY +
    //         "px)"
    //     );
    //   }
    console.log("加载");
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    window.isSmallDevice = mediaQuery.matches;
    console.log(window.window.filePath, window.window.iframeOrigin);

    const handleResize = () => (window.isSmallDevice = mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    // document.addEventListener("mousemove", setCursorPosition, false);
    return () => {
      mediaQuery.removeListener(handleResize);
      // document.removeEventListener("mousemove", setCursorPosition, false);
    };
  }, []);
  return (
    <>
      <App />
      {/* <span
        ref={cursorRef}
        className="cursor"
        style={{
          transform: cursorFocus ? "scale(300)" : "scale(1)",
          transition: cursorFocus ? "transform 2.5s ease-out" : "none",
        }}
      ></span>
      <span ref={cursorOutlineRef} className="cursor-outline"></span> */}
    </>
  );
}

createRoot(document.getElementById("root")).render(<Root />);
