import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useLoading } from "../../store";
const Scene = ({ IframeLoad }) => {
  const location = useLocation();
  const loading = useLoading((state) => state.loading);
  const iframeRef = useRef(null);

  return (
    <div>
      <iframe
        ref={iframeRef}
        onLoad={IframeLoad}
        style={{
          display: location.pathname === "/" && !loading ? "block" : "none",
          zIndex: location.pathname === "/" && !loading ? "53" : "0",
        }}
        title="HomeScene"
        className="h100 w-full fixed"
        src={`${window.iframeOrigin}/HomeScene/`}
      ></iframe>
    </div>
  );
};

export default Scene;
