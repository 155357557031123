import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Header = ({ t, jump }) => {
  const location = useLocation();

  const { i18n } = useTranslation();
  const [isEn, setIsEn] = useState(i18n.language === "en" ? false : true);
  const [onMenu, setOnMenu] = useState(false);
  const changeLanguage = () => {
    setIsEn(!isEn);
    i18n.changeLanguage(isEn ? "en" : "zh");
  };
  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="relative select-none">
      <div
        style={{ zIndex: 100 }}
        className="w-full bg-black text-white flex flex-row items-center justify-between p-2 lg:py-4 lg:px-10 fixed top-0"
      >
        <div className="hidden lg:flex">
          <div
            onClick={() => {
              jump("/");
            }}
            className="flex flex-row items-center cursor-pointer"
          >
            <i className="text-2xl cursor-pointer">&#xe62d;</i>
          </div>
          <nav className="hidden gap-4 lg:flex flex flex-row space-x-4 text-white font-bold leading-5 text-lg ml-10 mt-1">
            <div
              style={{
                borderBottom: location.pathname === "/" ? "2px solid #fff" : "",
              }}
              onClick={() => {
                jump("/");
              }}
              className="p-1 tracking-wide fontThin transition-all cursor-pointer duration-200 ease-in-out hover:text-gray-200 hover:border-white border-b-2 border-black"
            >
              {t("首页")}
            </div>
            <div
              onClick={() => {
                jump("item");
              }}
              style={{
                borderBottom:
                  location.pathname === "/item" ? "2px solid #fff" : "",
              }}
              className="p-1 tracking-wide fontThin transition-all cursor-pointer duration-200 ease-in-out hover:text-gray-200 hover:border-white border-b-2 border-black"
            >
              {t("项目")}
            </div>
            <div
              onClick={() => {
                jump("/about");
              }}
              style={{
                borderBottom:
                  location.pathname === "/about" ? "2px solid #fff" : "",
              }}
              className="p-1 tracking-wide fontThin transition-all cursor-pointer duration-200 ease-in-out hover:text-gray-200 hover:border-white border-b-2 border-black"
            >
              {t("关于")}
            </div>
            <div
              onClick={() => {
                jump("/partner");
              }}
              style={{
                borderBottom:
                  location.pathname === "/partner" ? "2px solid #fff" : "",
              }}
              className="p-1 tracking-wide fontThin transition-all cursor-pointer duration-200 ease-in-out hover:text-gray-200 hover:border-white border-b-2 border-black"
            >
              {t("伙伴")}
            </div>
            <div
              onClick={() => {
                jump("/awards");
              }}
              style={{
                borderBottom:
                  location.pathname === "/awards" ? "2px solid #fff" : "",
              }}
              className="p-1 tracking-wide fontThin transition-all cursor-pointer duration-200 ease-in-out hover:text-gray-200 hover:border-white border-b-2 border-black"
            >
              {t("奖项")}
            </div>
          </nav>
        </div>
        <div className="flex">
          <label
            style={{
              zIndex: 57,
              height: "32px",
            }}
            className="lg:hidden"
          >
            <div className="w-9 h-full cursor-pointer flex flex-col items-center justify-center">
              <input
                className="hidden peer"
                checked={onMenu}
                type="checkbox"
                onChange={() => setOnMenu(!onMenu)}
              />
              <div className="w-[50%] h-[2px] bg-white rounded-sm transition-all duration-300 origin-left translate-y-[0.45rem] peer-checked:rotate-[-45deg]"></div>
              <div className="w-[50%] h-[2px] bg-white rounded-md transition-all duration-300 origin-center peer-checked:hidden"></div>
              <div className="w-[50%] h-[2px] bg-white rounded-md transition-all duration-300 origin-left -translate-y-[0.45rem] peer-checked:rotate-[45deg]"></div>
            </div>
          </label>
          <label className="lg:flex relative inline-flex items-center cursor-pointer">
            <input
              className="sr-only peer"
              value=""
              type="checkbox"
              checked={isEn}
              onChange={() => changeLanguage()}
            />
            <div
              style={{
                zIndex: 57,
                transform: window.isSmallDevice
                  ? "scale(0.6) translateX(0rem)"
                  : "scale(1) translateX(0rem)",
              }}
              className="peer rounded-full outline-none  duration-100 after:duration-500  w-16 h-8 bg-white after:content-['EN'] after:absolute after:outline-none after:rounded-full after:h-6 after:w-6 after:bg-black after:top-1 after:left-1 after:flex after:justify-center after:items-center  after:text-white after:font-bold after:text-xs peer-checked:after:translate-x-8 peer-checked:after:content-['中'] peer-checked:after:border-black"
            ></div>
          </label>
        </div>
        <div
          onClick={() => {
            jump("/");
          }}
          className="lg:hidden flex-row items-center cursor-pointer"
        >
          <i className="text-xl  ml-2 lg:ml-4 cursor-pointer">&#xe62d;</i>
        </div>

        {/* 手机 */}
        <div
          style={{
            width: "80%",
            transform: onMenu ? "translateX(0)" : "translateX(-100%)",
            zIndex: 100,
            top: window.isSmallDevice ? "48px" : "66px",
          }}
          className="absolute text-white flex text-sm h100 flex-col items-center justify-between pb-4 text-black left-0 bg-black transition-all duration-300 ease-in-out overflow-hidden"
        >
          <div className="w-full px-2">
            <img
              className="w-2/4"
              src={window.filePath + "oxyz3.webp"}
              alt="oxyz3.logo"
            />
          </div>
          <nav className="w-full px-6 text-xl">
            <div
              className="w-fit"
              style={{
                borderBottom:
                  location.pathname === "/"
                    ? "2px solid #fff"
                    : "2px solid transparent",
              }}
            >
              <div
                onClick={() => {
                  setOnMenu(false);
                  jump("/");
                }}
              >
                {t("首页")}
              </div>
            </div>
            <div
              className="w-fit mt-2"
              style={{
                borderBottom:
                  location.pathname === "/item"
                    ? "2px solid #fff"
                    : "2px solid transparent",
              }}
            >
              <div
                onClick={() => {
                  setOnMenu(false);
                  jump("/item");
                }}
              >
                {t("项目")}
              </div>
            </div>
            <div
              className="w-fit mt-2"
              style={{
                borderBottom:
                  location.pathname === "/about"
                    ? "2px solid #fff"
                    : "2px solid transparent",
              }}
            >
              <div
                onClick={() => {
                  setOnMenu(false);
                  jump("/about");
                }}
              >
                {t("关于")}
              </div>
            </div>
            <div
              className="w-fit mt-2"
              style={{
                borderBottom:
                  location.pathname === "/partner"
                    ? "2px solid #fff"
                    : "2px solid transparent",
              }}
            >
              <div
                onClick={() => {
                  setOnMenu(false);
                  jump("/partner");
                }}
              >
                {t("伙伴")}
              </div>
            </div>
            <div
              className="w-fit mt-2"
              style={{
                borderBottom:
                  location.pathname === "/awards"
                    ? "2px solid #fff"
                    : "2px solid transparent",
              }}
            >
              <div
                onClick={() => {
                  setOnMenu(false);
                  jump("/awards");
                }}
              >
                {t("奖项")}
              </div>
            </div>
          </nav>
          <div className="w-full flex flex-col text-xs px-6">
            <div className="w-full flex flex-col">
              <span className="light-text-color">{t("联系")}</span>
              <span className="mt-2">{t("电话")}</span>
              <span className="mt-2">{t("邮箱")}</span>
            </div>
            <div className="w-full mt-4 flex flex-col">
              <span className="light-text-color">{t("地址")}</span>
              <span>{t("详细地址")}</span>
            </div>
          </div>
          {window.isSmallDevice && (
            <iframe
              className="w-full px-6"
              title="mapServer"
              src={`${window.iframeOrigin}/mapServer/`}
            ></iframe>
          )}
          <div className="flex px-6 w-full justify-between items-center z-20">
            <div
              onClick={() => handleClick("https://weibo.com/u/7742363070")}
              className="border-1px cursor-pointer rounded-full p-4 w-10 h-10 flex justify-center items-center"
            >
              <i className="text-4xl">&#xe635;</i>
            </div>
            <div
              onClick={() =>
                handleClick(
                  "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkzNTM0MTc4Mw==&action=getalbum&album_id=2914445660395896833&scene=173&subscene=&sessionid=svr_8d2415344e9&enterid=1721534643&from_msgid=2247484714&from_itemidx=1&count=3&nolastread=1#wechat_redirect"
                )
              }
              className="border-1px cursor-pointer rounded-full p-4 w-10 h-10 flex justify-center items-center"
            >
              <i className="text-4xl">&#xe636;</i>
            </div>
            <div
              onClick={() =>
                handleClick(
                  "https://www.douyin.com/user/MS4wLjABAAAA0tb_UDcsftI2uSOww2sBg5dmqFbS_pSW66-F_IdYMz0fmqtNdqgAgKoCbZyLA0JP"
                )
              }
              className="border-1px cursor-pointer rounded-full p-4 w-10 h-10 flex justify-center items-center"
            >
              <i className="text-4xl">&#xe637;</i>
            </div>
            <div
              onClick={() =>
                handleClick(
                  "https://space.bilibili.com/1340851698?spm_id_from=333.337.search-card.all.click"
                )
              }
              className="border-1px cursor-pointer rounded-full p-4 w-10 h-10 flex justify-center items-center"
            >
              <i className="text-xs">&#xe63b;</i>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: window.isSmallDevice ? "48px" : "66px",
        }}
      ></div>
    </div>
  );
};

export default Header;
