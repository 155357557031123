import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import AnimationLine from "../../components/AnimationLine";
import { typewriter } from "../../utils/index";
import { useTranslation } from "react-i18next";
import "./index.scss";

function Partner({ t }) {
  const [iframeHight, setIframeHeight] = useState(1);
  useEffect(() => {
    let debouncedResize;
    if (!window.isSmallDevice) {
      let resizeTimeout;

      const handleResize = () => {
        console.log("handleResize");
        if (window.innerWidth < 2015) {
          setIframeHeight(2);
        } else {
          setIframeHeight(1);
        }
      };

      debouncedResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(handleResize, 1000); // 1秒延迟
      };
      handleResize();
      // Add event listener
      window.addEventListener("resize", debouncedResize);
    }
    return () => {
      if (!window.isSmallDevice) {
        window.removeEventListener("resize", debouncedResize);
      }
    };
  }, []);

  return (
    <div
      className="h100 overflow-scroll"
      style={{
        zIndex: 100,
      }}
    >
      <TitleBar title={t("投资方")} />
      <h1>{window.isSmallDevice}</h1>
      <IframeBox
        cardWrap={!window.isSmallDevice ? "wrap" : "nowrap"}
        hight="340px"
        cardType={"investor"}
      />
      <TitleBar title={t("客户")} />
      <IframeBox
        cardWrap={!window.isSmallDevice ? "wrap" : "nowrap"}
        hight={window.isSmallDevice ? "340px" : iframeHight * 360 + "px"}
        cardType={"clients"}
      />
      <TitleBar title={t("合作团队")} />
      <IframeBox
        cardWrap={!window.isSmallDevice ? "wrap" : "nowrap"}
        hight={window.isSmallDevice ? "340px" : iframeHight * 360 + 360 + "px"}
        cardType={"collaborating"}
      />
    </div>
  );
}

function IframeBox({ cardType, cardWrap, hight }) {
  const iframeRef = useRef(null);
  const { i18n } = useTranslation();
  return (
    <div
      className="flex items-center justify-evenly relative"
      style={{ height: hight }}
    >
      <iframe
        title="cardList"
        ref={iframeRef}
        style={{ height: "100%" }}
        className="iframe"
        allow="accelerometer"
        src={`${window.iframeOrigin}/card/?card_type=${cardType}&card_size=${
          window.isSmallDevice ? 150 : 190
        }&card_align=center&card_wrap=${cardWrap}&lang=${i18n.language}`}
        // src={`http://192.168.50.191:5173/?card_list=[${cardList}]&card_size=${
        //   window.isSmallDevice ? 150 : 190
        // }&card_align=center&card_wrap=${cardWrap}&lang=${i18n.language}`}
      ></iframe>
      <div></div>
    </div>
  );
}

export function TitleBar({ title }) {
  const titleBarRef = useRef(null);
  const backgroundRef = useRef(null);
  const textRef = useRef(null);
  useEffect(() => {
    const element = textRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // 元素进入视口时开始动画
          typewriter(element, title, 5, 10, "ABCDEFGHIIIJKLNOPQRSTUVYZ");
          // 动画完成后取消观察
          observer.unobserve(element);
        }
      },
      {
        threshold: 1.0, // 元素完全进入视口时触发回调
      }
    );
    observer.observe(element);
    // const handleMouseEnter = () => {
    //   gsap.to(backgroundRef.current, {
    //     height: "100%",
    //     duration: 0.3,
    //     ease: "power2.out",
    //   });
    //   gsap.to(element, {
    //     color: "black",
    //     duration: 0.3,
    //     scale: 0.8,
    //     ease: "power2.out",
    //   });
    // };

    // const handleMouseLeave = () => {
    //   gsap.to(backgroundRef.current, {
    //     height: "0%",
    //     duration: 0.3,
    //     ease: "power2.out",
    //   });
    //   gsap.to(element, {
    //     color: "white",
    //     duration: 0.3,
    //     scale: 1,
    //     ease: "power2.out",
    //   });
    // };

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, []);
  return (
    <div
      ref={titleBarRef}
      className="relative title-bar px-2  flex justify-center items-center fontBold lg:py-6"
    >
      <AnimationLine
        classList="line-t border-t w-full h-[1px]"
        animation={{
          duration: 2,
          width: "100%",
          ease: "power1.inOut",
        }}
      />
      <div
        ref={backgroundRef}
        className="absolute hidden lg:block top-0 left-0 w-full h-0 bg-white pointer-events-none"
      ></div>
      <span
        ref={textRef}
        className="text-2xl py-2 px-2 lg:py-0 lg:text-4xl z-10"
      >
        {title}
      </span>
      <AnimationLine
        classList="line-b border-b w-full h-[1px]"
        animation={{
          duration: 2,
          width: "100%",
          ease: "power1.inOut",
        }}
      />
    </div>
  );
}

export default Partner;
