import React, { useState } from "react";
import AnimationLine from "./AnimationLine";
const Footer = ({ jump, t }) => {
  const images = [
    `${window.filePath}room.png`,
    `${window.filePath}room_2.png`,
    `${window.filePath}room_3.png`,
    `${window.filePath}room_4.png`,
  ];
  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="hidden lg:block w-full relative">
      <AnimationLine
        classList="line-t border-t w-full h-[1px]"
        animation={{
          duration: 3,
          width: "100%",
          ease: "power3.out",
        }}
      ></AnimationLine>
      {/* <div className="relative h-[400px]">
        <iframe
          title="mapServer"
          className="iframe"
          allow="accelerometer"
          src="https://tem.neowulin.com/mapServer"
        ></iframe>
      </div> */}
      {/* <div className="flex py-10 justify-between items-center">
        <span>浙ICP备2022025146号-3 </span>
        <div className="border rounded-full px-4 py-2 w-20 h-10">MAILS</div>
        <div className="flex z-20">
          <div
            onClick={() => handleClick("https://weibo.com/u/7742363070")}
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-6xl">&#xe635;</i>
          </div>
          <div
            onClick={() =>
              handleClick(
                "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkzNTM0MTc4Mw==&action=getalbum&album_id=2914445660395896833&scene=173&subscene=&sessionid=svr_8d2415344e9&enterid=1721534643&from_msgid=2247484714&from_itemidx=1&count=3&nolastread=1#wechat_redirect"
              )
            }
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center mx-6"
          >
            <i className="text-6xl">&#xe636;</i>
          </div>

          <div
            onClick={() =>
              handleClick(
                "https://www.douyin.com/user/MS4wLjABAAAA0tb_UDcsftI2uSOww2sBg5dmqFbS_pSW66-F_IdYMz0fmqtNdqgAgKoCbZyLA0JP"
              )
            }
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-6xl">&#xe637;</i>
          </div>
        </div>
      </div> */}

      {/* <div className="w-full flex flex-wrap justify-between p-10">
        <div className="w-1/2 h-[400px]  pr-10">
          <img
            className="w-full h-full object-cover"
            src={`${window.filePath}room.png`}
            alt=""
          />
        </div>
        <div className="w-1/2  h-[400px] ">
          <img
            className="w-full h-full object-cover"
            src={`${window.filePath}room_2.png`}
            alt=""
          />
        </div>
      </div> */}

      <div className="w-full h-[600px] relative flex flex-wrap justify-center content-center">
        <img
          src={window.filePath + "bg.png"}
          style={{ width: "calc(100% - 5rem)" }}
          className="w-full h-full absolute top-0 left-10 object-cover py-10"
          alt=""
        />
        <div
          style={{ background: "#0e0e0e79" }}
          className="w-full h-full absolute top-0 left-0 object-cover"
        ></div>
        <div className="flex w-full justify-center items-center gap-6 z-20">
          <div
            onClick={() => handleClick("https://weibo.com/u/7742363070")}
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-6xl">&#xe635;</i>
          </div>
          <div
            onClick={() =>
              handleClick(
                "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkzNTM0MTc4Mw==&action=getalbum&album_id=2914445660395896833&scene=173&subscene=&sessionid=svr_8d2415344e9&enterid=1721534643&from_msgid=2247484714&from_itemidx=1&count=3&nolastread=1#wechat_redirect"
              )
            }
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-6xl">&#xe636;</i>
          </div>
          <div
            onClick={() =>
              handleClick(
                "https://www.douyin.com/user/MS4wLjABAAAA0tb_UDcsftI2uSOww2sBg5dmqFbS_pSW66-F_IdYMz0fmqtNdqgAgKoCbZyLA0JP"
              )
            }
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-6xl">&#xe637;</i>
          </div>
          <div
            onClick={() =>
              handleClick(
                "https://space.bilibili.com/1340851698?spm_id_from=333.337.search-card.all.click"
              )
            }
            className="border-1px cursor-pointer rounded-full p-6 w-16 h-16 flex justify-center items-center"
          >
            <i className="text-xl">&#xe63b;</i>
          </div>
        </div>
        <div className="z-20 mt-12 fontBold text-6xl">{t("关注我们")}</div>
      </div>
      <div className="h-60 flex justify-between p-10">
        <div className="h-full flex flex-col justify-between">
          <img className="logo" src={window.filePath + "oxyz3.webp"} alt="" />
          <span className="ml-4">浙ICP备2022025146号-3 </span>
        </div>
        <div className="flex w-1/2">
          <div className="w-1/2 h-40  flex flex-col">
            <span className="light-text-color  select-none">{t("链接")}</span>
            <span
              onClick={() => {
                jump("/");
              }}
              className="mt-2 hover:text-neutral-400 cursor-pointer"
            >
              {t("首页")}
            </span>
            <span
              onClick={() => {
                jump("/item");
              }}
              className="mt-2 hover:text-neutral-400 cursor-pointer"
            >
              {t("项目")}
            </span>
            <span
              onClick={() => {
                jump("/about");
              }}
              className="mt-2 hover:text-neutral-400 cursor-pointer"
            >
              {t("关于")}
            </span>
            <span
              onClick={() => {
                jump("/partner");
              }}
              className="mt-2 hover:text-neutral-400 cursor-pointer"
            >
              {t("伙伴")}
            </span>
            <span
              onClick={() => {
                jump("/awards");
              }}
              className="mt-2 hover:text-neutral-400 cursor-pointer"
            >
              {t("奖项")}
            </span>
          </div>
          <div className="w-1/2 h-40  flex flex-col">
            <span className="light-text-color">{t("联系")}</span>
            <span className="mt-2">(+86)19957105357</span>
            <span className="mt-2">oxyz3@163.com</span>
          </div>
          <div className="w-1/2 h-40  flex flex-col whitespace-pre-wrap">
            <span className="light-text-color">{t("地址")}</span>
            <span>{t("详细地址")}</span>
          </div>
        </div>
      </div>
      <div className="relative w-full p-4 flex justify-center item-center">
        <AnimationLine
          classList="line-t border-t w-full h-[1px]"
          animation={{
            duration: 3,
            width: "100%",
            ease: "power3.out",
          }}
        ></AnimationLine>
        <i className="text-4xl font-bold">&#xe641;</i>
      </div>
    </div>
  );
};

export default Footer;
