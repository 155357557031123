import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import BackButton from "../../components/BackButton.jsx";
import AnimationLine from "../../components/AnimationLine.jsx";
import AnimationText from "../../components/AnimationText.jsx";
import Footer from "../../components/Footer.jsx";
import styles from "./index.module.css";
import axios from "axios";

function About({ jump, t }) {
  const { i18n } = useTranslation();
  const [coreBusiness, setCoreBusiness] = useState([]);
  const [otherBusiness, setOtherBusiness] = useState([]);
  const [appraise, setAppraise] = useState([]);
  const [consultant, setConsultant] = useState([]);
  // 请求接口
  useEffect(() => {
    const timestamp = new Date().getTime();
    axios
      .get(
        `/wp-json/wp/v2/corebusiness?_=${timestamp}&per_page=100&_fields=id,acf`
      )
      .then((res) => {
        setCoreBusiness(
          res.data
            .sort((a, b) => a.acf.sort - b.acf.sort) // 根据sort字段升序排序
            .map((item) => ({
              ...item.acf,
              id: item.id,
            }))
        );
      });
    axios
      .get(
        `/wp-json/wp/v2/otherbusiness?_=${timestamp}&per_page=100&_fields=id,acf`
      )
      .then((res) => {
        setOtherBusiness(
          res.data
            .sort((a, b) => a.acf.sort - b.acf.sort) // 根据sort字段升序排序
            .map((item) => ({
              ...item.acf,
              id: item.id,
            }))
        );
      });
    // axios
    //   .get(
    //     `/wp-json/wp/v2/consultant?_=${timestamp}&per_page=100&_fields=id,acf&acf_format=standard`
    //   )
    //   .then((res) => {
    //     setConsultant(
    //     res.data
    //     .sort((a, b) => a.acf.sort - b.acf.sort) // 根据sort字段升序排序
    //     .map((item) => ({
    //       ...item.acf,
    //       id: item.id,
    //     }))
    //     );
    //   });
    axios
      .get(
        `/wp-json/wp/v2/appraise?_=${timestamp}&per_page=100&_fields=id,acf&acf_format=standard`
      )
      .then((res) => {
        setAppraise(
          res.data
            .sort((a, b) => a.acf.sort - b.acf.sort) // 根据sort字段升序排序
            .map((item) => ({
              ...item.acf,
              id: item.id,
            }))
        );
      });
  }, []);

  return (
    <div className="relative top-0 h100 overflow-y-scroll">
      <Profile t={t}></Profile>
      <div className="w-full relative px-4 pb-0 lg:p-10 lg:pb-0">
        {
          <i style={{ fontWeight: 700 }} className="font-bold text-3xl">
            &#xe63d;
          </i>
        }
        <h1 className="mt-4 fontBold text-2xl lg:text-4xl relative w-fit">
          {t("核心业务")}
        </h1>
      </div>
      <Business
        list={coreBusiness}
        t={t}
        height={window.isSmallDevice ? "6rem" : "12rem"}
      ></Business>
      <div className="w-full relative  px-4 lg:px-10">
        {
          <i style={{ fontWeight: 700 }} className="font-bold text-3xl">
            &#xe63d;
          </i>
        }
        <h1 className="mt-4 fontBold text-2xl lg:text-4xl relative w-fit">
          {t("其他业务")}
        </h1>
      </div>
      <Business t={t} list={otherBusiness} width="100%"></Business>
      <Team appraise={appraise} t={t}></Team>
      <Footer jump={jump} t={t} />
    </div>
  );
}

// function Billboards() {
//   return (
//     <div>
//       {window.isSmallDevice ? (
//         <div>
//           <div className="w-full px-14 py-10">
//             <div className="border border-white w-full h-[200px]"></div>
//           </div>
//           <div className="w-full">
//             {/* 工作内容 */}
//             <div className="mb-6">
//               <h1 className="border-b border-white py-2 text-3xl">工作内容1</h1>
//               <div className="fontThin py-2">
//                 OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营的枢纽团队。作为一家独具匠心
//                 的ART
//                 ech先锋企业，巧妙地编织艺术与科技经纬，专攻于开拓"虚实共生"的无垠创新强域。团队精通
//                 于一站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉听觉美学与最前端科技深度
//                 交融
//                 业务范畴广泛渗透至虚拟世界的整体策划、虚拟音乐会创作承办、传统产业升级的数字化转型、多媒
//                 体展示厅构建，以及融汇艺术与科技精华的原创作品设计。尤为撞长打造音乐主题的视听盛宴，并在
//                 构筑线上线下无缝衔接的"第三空间"体验中树立标杆。
//                 短短一年时间，轴厂已为淘宝、新华网、次世文化、Cyber Manufacture
//                 Co等众多海内外知名项目方
//                 制作元宇宙全案项目，同时，轴厂自研的虚拟演出作品「新武林-众方纪元」VR版，也新获了SW
//                 SX Film Festival西南偏南电影节）的观众选择奖。
//               </div>
//             </div>
//             {/* 工作内容 */}
//             <div className="mb-6">
//               <h1 className="border-b border-white py-2 text-3xl">工作内容1</h1>
//               <div className="fontThin py-2">
//                 OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营的枢纽团队。作为一家独具匠心
//                 的ART
//                 ech先锋企业，巧妙地编织艺术与科技经纬，专攻于开拓"虚实共生"的无垠创新强域。团队精通
//                 于一站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉听觉美学与最前端科技深度
//                 交融
//                 业务范畴广泛渗透至虚拟世界的整体策划、虚拟音乐会创作承办、传统产业升级的数字化转型、多媒
//                 体展示厅构建，以及融汇艺术与科技精华的原创作品设计。尤为撞长打造音乐主题的视听盛宴，并在
//                 构筑线上线下无缝衔接的"第三空间"体验中树立标杆。
//                 短短一年时间，轴厂已为淘宝、新华网、次世文化、Cyber Manufacture
//                 Co等众多海内外知名项目方
//                 制作元宇宙全案项目，同时，轴厂自研的虚拟演出作品「新武林-众方纪元」VR版，也新获了SW
//                 SX Film Festival西南偏南电影节）的观众选择奖。
//               </div>
//             </div>
//             {/* 工作内容 */}
//             <div className="mb-6">
//               <h1 className="border-b border-white py-2 text-3xl">工作内容1</h1>
//               <div className="fontThin py-2">
//                 OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营的枢纽团队。作为一家独具匠心
//                 的ART
//                 ech先锋企业，巧妙地编织艺术与科技经纬，专攻于开拓"虚实共生"的无垠创新强域。团队精通
//                 于一站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉听觉美学与最前端科技深度
//                 交融
//                 业务范畴广泛渗透至虚拟世界的整体策划、虚拟音乐会创作承办、传统产业升级的数字化转型、多媒
//                 体展示厅构建，以及融汇艺术与科技精华的原创作品设计。尤为撞长打造音乐主题的视听盛宴，并在
//                 构筑线上线下无缝衔接的"第三空间"体验中树立标杆。
//                 短短一年时间，轴厂已为淘宝、新华网、次世文化、Cyber Manufacture
//                 Co等众多海内外知名项目方
//                 制作元宇宙全案项目，同时，轴厂自研的虚拟演出作品「新武林-众方纪元」VR版，也新获了SW
//                 SX Film Festival西南偏南电影节）的观众选择奖。
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="w-full h-full py-36 px-80" style={{ height: "840px" }}>
//           <div className="flex flex-wrap w-full h-full">
//             <div className="w-40 h-40 border-t border-x  border-white"></div>
//             <div
//               className="flex items-center pl-10 h-40 border-t border-r border-white text-7xl font-bold"
//               style={{ width: "calc(100% - 10rem)" }}
//             >
//               {" "}
//               关于我们
//             </div>
//             <div
//               className="w-full flex  border-y border-white"
//               style={{ height: "calc(100% - 10rem)" }}
//             >
//               <div className="border-x border-white w-40 h-full">
//                 <div className="flex items-center justify-center p-4 border-b border-white hover:bg-white hover:text-black font-semibold ">
//                   工作内容
//                 </div>
//                 <div className="flex items-center justify-center p-4 border-b border-white hover:bg-white hover:text-black font-semibold ">
//                   工作内容
//                 </div>
//                 <div className="flex items-center justify-center p-4 border-b border-white hover:bg-white hover:text-black font-semibold ">
//                   工作内容
//                 </div>
//               </div>
//               <div className="border-white border-r w-3/5 h-full p-10">
//                 OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营的枢纽团队。作为一家独具匠心
//                 的ART
//                 ech先锋企业，巧妙地编织艺术与科技经纬，专攻于开拓"虚实共生"的无垠创新强域。团队精通
//                 于一站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉听觉美学与最前端科技深度
//                 交融
//                 业务范畴广泛渗透至虚拟世界的整体策划、虚拟音乐会创作承办、传统产业升级的数字化转型、多媒
//                 体展示厅构建，以及融汇艺术与科技精华的原创作品设计。尤为撞长打造音乐主题的视听盛宴，并在
//                 构筑线上线下无缝衔接的"第三空间"体验中树立标杆。
//                 短短一年时间，轴厂已为淘宝、新华网、次世文化、Cyber Manufacture
//                 Co等众多海内外知名项目方
//                 制作元宇宙全案项目，同时，轴厂自研的虚拟演出作品「新武林-众方纪元」VR版，也新获了SW
//                 SX Film Festival西南偏南电影节）的观众选择奖。
//               </div>
//               <div className="border-r border-white flex-1 h-full"></div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

function Profile({ t }) {
  const { i18n } = useTranslation();
  return (
    <div>
      {window.isSmallDevice ? (
        <div className="flex flex-col w-full relative">
          {/* 顶部线 */}
          <AnimationLine
            classList="line-b border-t w-full h-[1px]"
            animation={{
              duration: 3,
              width: "100%",
              ease: "power3.out",
            }}
          />
          {/* 底部线 */}
          <AnimationLine
            classList="line-y border-t w-full h-[1px]"
            animation={{
              duration: 3,
              width: "100%",
              ease: "power1.inOut",
            }}
          />
          <div className="flex-1 relative">
            <div className="w-full relative text-3xl fontBold p-4 text-center">
              <AnimationText
                classList="text-left"
                text={t("关于OXYZ3")}
              ></AnimationText>
              {/* 标题下部线 */}
              <AnimationLine
                classList="line-y border-b h-full"
                animation={{
                  duration: 3,
                  width: "100%",
                  ease: "power3.out",
                }}
              />
            </div>
            <AnimationLine
              classList="line-x border-l w-full"
              animation={{
                duration: 4,
                height: "100%",
                ease: "power3.out",
              }}
            />
            <div className="w-full h-full">
              <div className="flex justify-center items-center h-full relative">
                <CircularBorderAnimation></CircularBorderAnimation>
                <img
                  className="p-4"
                  src={window.filePath + "logo.webp"}
                  alt=""
                />
              </div>
            </div>
            <div className="p-4">
              {i18n.language === "zh" ? (
                <div>{t("关于1")}</div>
              ) : (
                <div>
                  {t("关于1")}
                  <br />
                  <br />
                  {t("关于2")}
                  <br />
                  <br />
                  {t("关于3")}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full relative h-[900px]">
          {/* 顶部线 */}
          <AnimationLine
            classList="line-b border-t w-full h-[1px]"
            animation={{
              duration: 3,
              width: "100%",
              ease: "power3.out",
            }}
          />
          {/* 底部线 */}
          <AnimationLine
            classList="line-y border-t w-full h-[1px]"
            animation={{
              duration: 3,
              width: "100%",
              ease: "power1.inOut",
            }}
          />
          <div className="flex-1 relative">
            <div className="w-full relative text-6xl fontBold p-10 text-center">
              <AnimationText
                classList="text-left"
                text={t("关于OXYZ3")}
              ></AnimationText>
              {/* 标题下部线 */}
              <AnimationLine
                classList="line-y border-b h-full"
                animation={{
                  duration: 3,
                  width: "100%",
                  ease: "power3.out",
                }}
              />
            </div>
            <AnimationLine
              classList="line-r border-r w-[1px] mt-[1px] mr-[-1px]"
              animation={{
                duration: 4,
                height: "100%",
                ease: "power3.out",
              }}
            />
            <div className="p-10">
              {i18n.language === "zh" ? (
                <div>{t("关于1")}</div>
              ) : (
                <div>
                  {t("关于1")}
                  <br />
                  <br />
                  {t("关于2")}
                  <br />
                  <br />
                  {t("关于3")}
                </div>
              )}
            </div>
          </div>
          <div className="w-[60%] h-full">
            <div className="flex justify-center items-center h-full relative">
              <CircularBorderAnimation></CircularBorderAnimation>
              <img src={window.filePath + "logo.webp"} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function Business({ list, height, width }) {
  const { i18n } = useTranslation();
  return (
    <div className="w-full h-auto p-4 pt-0 lg:p-10 lg:pt-0">
      <div className="w-full relative pb-4"></div>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 gap-4 lg:gap-14">
        {list &&
          list.map((item, index) => {
            return (
              <div key={index} className="flex justify-between items-center">
                <div className="flex w-full relative">
                  <AnimationLine
                    classList="line-t border-t w-full h-[1px]"
                    animation={{
                      duration: 2,
                      width: "100%",
                      ease: "power1.inOut",
                    }}
                  />
                  <AnimationLine
                    classList="line-b border-b w-full h-[1px]"
                    animation={{
                      duration: 2,
                      width: "100%",
                      ease: "power1.inOut",
                    }}
                  />
                  <div
                    className="flex w-full py-2 lg:py-4"
                    style={{ height: height }}
                  >
                    <div
                      className="text-sm lg:text-3xl w-2/5 pr-2 fontThin"
                      style={{ width: width }}
                    >
                      {i18n.language === "zh" ? item.title_zh : item.title_en}
                    </div>
                    <div className="text-xs lg:text-2xl w-3/5 h-full overflow-y-scroll fontThin light-text-color">
                      {i18n.language === "zh"
                        ? item.content_zh
                        : item.content_en}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function Team({ appraise, t }) {
  const elementRef = useRef();
  const [isNext, setIsNext] = useState(false);
  const { i18n } = useTranslation();
  return (
    <div ref={elementRef} className="h-auto">
      <div className="relative" style={{ zIindex: 1 }}>
        <AnimationLine
          classList="line-t border-t w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
        <AnimationText
          text={t("我们的团队")}
          classList="mt-4 fontBold text-3xl lg:text-6xl p-4 lg:p-10 w-fit "
        ></AnimationText>
        <AnimationLine
          classList="line-b border-b w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
      </div>

      {/* <div className="px-4 py-4 lg:px-10 lg:py-6">
        Virtual world full case， Very extensive experience in the industry
      </div> */}
      <div className="w-full">
        <div className="p-4 lg:px-10 lg:py-20 pb-6 flex items-center flex-col lg:flex-row">
          {isNext ? (
            <div className="w-full p-6 lg:w-[25vw] lg:p-0">
              <img src={window.filePath + "zj.png"} className="w-full" alt="" />
            </div>
          ) : (
            <div className="w-full  p-6 lg:w-[25vw] lg:p-0">
              <img
                src={window.filePath + "ccc.png"}
                className="w-full "
                style={{ zIindex: -1 }}
              />
            </div>
          )}
          {window.isSmallDevice ? (
            <div className="w-full tracking-wide  flex flex-col justify-between">
              <div className="w-full flex justify-between items-center">
                <div className="flex justify-between items-end">
                  <AnimationText
                    text={isNext ? t("Jacob") : t("陈陈陈")}
                    classList="fontBold text-xl w-fit"
                  ></AnimationText>
                </div>
                <BackButton
                  onClick={() => {
                    setIsNext(!isNext);
                  }}
                  isBack={true}
                ></BackButton>
              </div>
              {isNext ? (
                <div className={`${styles.content} text-sm`}>{t("zj")}</div>
              ) : (
                <div className={styles.content}>{t("ccc")}</div>
              )}
            </div>
          ) : (
            <div className="w-3/4  gap-12 tracking-wide pl-20 flex flex-col justify-between">
              <div className="w-full  flex justify-between items-end">
                <div className="flex  justify-between items-end ">
                  <AnimationText
                    text={isNext ? t("Jacob") : t("陈陈陈")}
                    classList="fontBold text-4xl w-fit"
                  ></AnimationText>
                </div>
                <BackButton
                  onClick={() => {
                    setIsNext(!isNext);
                  }}
                  isBack={true}
                ></BackButton>
              </div>
              {isNext ? (
                <div className={`${styles.content} text-xl`}>{t("zj")}</div>
              ) : (
                <div className={`${styles.content} text-xl`}>{t("ccc")}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <Consultant t={t} consultant={consultant}></Consultant> */}

      <div className="relative">
        <AnimationLine
          classList="line-t border-t w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
        <AnimationText
          text={t("客户评价")}
          classList="mt-4 fontBold text-3xl lg:text-6xl p-4 lg:p-10 w-fit"
        ></AnimationText>
        <AnimationLine
          classList="line-b border-b w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
      </div>
      {/* <div className="px-4 py-4 lg:px-10 lg:py-6">
        Virtual world full case， Very extensive experience in the industry
      </div> */}
      {/* 卡片列表 */}

      <div className="flex flex-col justify-evenly lg:flex-row p-4 lg:py-16 pb-6 ">
        {/* 单个卡片 */}
        {appraise.map((item, index) => {
          return (
            <div
              className="w-full lg:w-[22rem] relative flex flex-col items-center h-full lg:px-10"
              key={index}
            >
              <SquareAnimation
                children={
                  <div className="absolute top-0 left-0 p-6 w-full aspect-square flex flex-col justify-center">
                    <div className="text-center italic">
                      {i18n.language === "zh"
                        ? item.comments_zh
                        : item.comments_en}
                    </div>
                  </div>
                }
              ></SquareAnimation>
              <div className="flex flex-col items-center translate-y-[-30%]">
                <img
                  className="w-24 h-24 rounded-full object-cover z-10"
                  src={item.avatar}
                  alt="client"
                />
                <div className="my-2 fontBold">
                  {i18n.language === "zh" ? item.name_zh : item.name_en}
                </div>
                <div>
                  {i18n.language === "zh"
                    ? item.organization_zh
                    : item.organization_en}
                </div>
                <div className="text-xs mt-2">
                  {i18n.language === "zh" ? item.role_zh : item.role_en}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const CircularBorderAnimation = () => {
  const circleRef = useRef(null);

  useEffect(() => {
    const circle = circleRef.current;
    const length = circle.getTotalLength() + 1;

    gsap.set(circle, {
      strokeDasharray: length,
      strokeDashoffset: length,
    });

    gsap.to(circle, {
      strokeDashoffset: 0,
      duration: 2,
      ease: "power1.inOut",
    });
  }, []);

  return (
    <svg
      className="absolute top-0 p-0 lg:p-10"
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
    >
      <circle
        ref={circleRef}
        cx="100"
        cy="100"
        r="90"
        stroke="#606060"
        strokeWidth="0.4"
        fill="none"
      />
    </svg>
  );
};

const SquareAnimation = ({ children }) => {
  const squareRef = useRef(null);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const square = squareRef.current;
      const length = square.getTotalLength();

      // 初始化路径
      gsap.set(square, {
        strokeDasharray: length + 12,
        strokeDashoffset: length + 12,
      });

      // 创建动画
      gsap.to(square, {
        strokeDashoffset: 0,
        duration: 2,
        ease: "power1.inOut",
      });
    }
  }, [isVisible]);

  return (
    <div className="w-full aspect-square relative" ref={containerRef}>
      <svg className="w-full h-full ">
        <rect
          ref={squareRef}
          x="0"
          y="0"
          width="100%"
          height="100%"
          stroke="white"
          strokeWidth="2"
          fill="none"
        />
      </svg>
      {children}
    </div>
  );
};

const Consultant = ({ t, consultant }) => {
  const { i18n } = useTranslation();
  return (
    <div>
      <div className="w-full flex relative">
        <AnimationLine
          classList="line-t border-t w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
        <AnimationText
          text={t("顾问")}
          classList="lg:mt-4 fontBold text-3xl lg:text-6xl p-4 lg:p-10 w-fit"
        ></AnimationText>
        <AnimationLine
          classList="line-b border-b w-full h-[1px]"
          animation={{
            duration: 2,
            width: "100%",
            ease: "power1.inOut",
          }}
        />
      </div>
      <div className="w-full flex flex-col gap-12 lg:flex-row items-center lg:justify-center p-10">
        {consultant.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full flex flex-col items-center justify-center"
            >
              <img
                className="w-60 h-60 object-cover"
                src={item.image.url}
                alt="consultant.image"
              />
              <div className="w-full flex flex-col lg:text-2xl text-lg items-center justify-center p-2">
                {i18n.language === "zh" ? item.introduce_zh : item.introduce_en}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// function ItemInfo() {
//   return (
//     <div>
//       {window.isSmallDevice ? (
//         <div className="w-full">
//           <div className="w-full flex flex-col items-center justify-center  pt-2 pb-4">
//             <div className="border border-white w-[200px] h-[200px] my-4"></div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//           </div>
//           <div className="w-full flex flex-col items-center justify-center pt-2 pb-4">
//             <div className="border border-white w-[200px] h-[200px] my-4"></div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//           </div>
//           <div className="w-full flex flex-col items-center justify-center pt-2 pb-4">
//             <div className="border border-white w-[200px] h-[200px] my-4"></div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//             <div className="w-full border-b py-2 text-2xl">虚拟演唱会</div>
//           </div>
//         </div>
//       ) : (
//         <div className="flex w-full flex-col items-center justify-center p-10">
//           <div className="w-full h-80 flex  py-10 px-40">
//             <div className="border w-60 h-60 border-white"></div>
//             <div className="flex flex-wrap justify-between flex-1 p-10">
//               <div className="border-b text-lg mx-4 w-2/5 border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className="border-b text-lg mx-4 flex-initial border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className="border-b text-lg mx-4 w-2/5 border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className="border-b text-lg mx-4 flex-initial border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//             </div>
//           </div>
//           <div className="w-full h-80 flex  py-10 px-40">
//             <div className="w-60 h-60"></div>
//             <div className="flex flex-col justify-between  w-3/5 p-10">
//               <div className="border-b mx-4 border-white py-4 w-4/5 h-12 text-lg">
//                 虚拟演唱会
//               </div>
//               <div className="border-b mx-4 text-lg border-white py-4 w-4/5 h-12">
//                 虚拟演唱会
//               </div>
//             </div>
//             <div className="border w-60 h-60 border-white"></div>
//           </div>
//           <div className="w-full h-80 flex  py-10 px-40">
//             <div className="border w-60 h-60 border-white"></div>
//             <div className="flex flex-wrap justify-between flex-1 p-10">
//               <div className="text-lg border-b mx-4 w-2/5 border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className=" text-lg border-b mx-4 flex-initial border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className="text-lg border-b mx-4 w-2/5 border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//               <div className="text-lg border-b mx-4 flex-initial border-white py-4 w-1/2 h-12">
//                 虚拟演唱会
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// function ImgWall() {
//   return (
//     <div
//       className="w-full flex px-40 py-20 justify-evenly items-center"
//       style={{ height: "700px" }}
//     >
//       <div className="flex-1 h-full border border-white"></div>
//       <div className="flex-1 h-full border border-white"></div>
//       <div className="flex-1 h-full border border-white"></div>
//       <div className="flex-1 h-full border border-white"></div>
//       <div className="flex-1 h-full border border-white"></div>
//       <div className="flex-1 h-full border border-white"></div>
//     </div>
//   );
// }

// function ItemImg() {
//   return (
//     <div>
//       {window.isSmallDevice ? (
//         <div className="w-full flex flex-col lg:flex-row h-auto p-6 lg:px-40 lg:py-20 justify-evenly items-center">
//           <div className="aspect-video h-[150px] border border-white mb-2"></div>
//           <div className="aspect-video h-[150px] border border-white mt-2"></div>
//         </div>
//       ) : (
//         <div className="w-full flex flex-col lg:flex-row h-auto p-6 lg:px-40 lg:py-20 justify-evenly items-center">
//           <div className="aspect-video h-[350px] border border-white"></div>
//           <div className="aspect-video h-[350px] border border-white"></div>
//         </div>
//       )}
//     </div>
//   );
// }

// function ItemEvaluation() {
//   return (
//     <div>
//       {window.isSmallDevice ? (
//         <div className="py-4 h-full">
//           <div className="flex justify-between items-center bg-neutral-700/[.3] h-[450px] w-full mb-4">
//             <div className="border-white border-r w-8 h-full"></div>
//             <div className="p-6 flex flex-col justify-between items-center h-full w-full">
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8 fontThin">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-2 fontThin">xx合作方</div>
//                   <div className="py-2 fontThin">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-20 h-20"></div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between items-center bg-neutral-700/[.3] h-[450px] w-full mb-4">
//             <div className="border-white border-r w-8 h-full"></div>
//             <div className="p-6 flex flex-col justify-between items-center h-full w-full">
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8 fontThin">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-2 fontThin">xx合作方</div>
//                   <div className="py-2 fontThin">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-20 h-20"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div
//           style={{
//             height: "1000px",
//           }}
//           className="grid grid-cols-2 grid-rows-2 gap-10 px-40 py-20 h-1200"
//         >
//           <div className="flex justify-between items-center pl-10 bg-neutral-700/[.3]">
//             <div className="border-white border-r w-6 h-full"></div>
//             <div
//               style={{
//                 width: "calc(100% - 2rem)",
//               }}
//               className="p-10 h-full flex flex-col justify-between items-center"
//             >
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-4">xx合作方</div>
//                   <div className="py-4">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-28 h-28"></div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between items-center pl-10 bg-neutral-700/[.3]">
//             <div className="border-white border-r w-6 h-full"></div>
//             <div
//               style={{
//                 width: "calc(100% - 2rem)",
//               }}
//               className="p-10 h-full flex flex-col justify-between items-center"
//             >
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-4">xx合作方</div>
//                   <div className="py-4">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-28 h-28"></div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between items-center pl-10 bg-neutral-700/[.3]">
//             <div className="border-white border-r w-6 h-full"></div>
//             <div
//               style={{
//                 width: "calc(100% - 2rem)",
//               }}
//               className="p-10 h-full flex flex-col justify-between items-center"
//             >
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-4">xx合作方</div>
//                   <div className="py-4">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-28 h-28"></div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between items-center pl-10 bg-neutral-700/[.3]">
//             <div className="border-white border-r w-6 h-full"></div>
//             <div
//               style={{
//                 width: "calc(100% - 2rem)",
//               }}
//               className="p-10 h-full flex flex-col justify-between items-center"
//             >
//               <div>
//                 <div>★ ★ ★ ★ ★</div>
//                 <div className="mt-8">
//                   OXYZ3轴厂是一家专注于数字内容创作策划、全案执行以及商务运营
//                   的枢纽团队。作为一家独具匠心的ART ech先锋企业，巧妙地编织艺术
//                   与科技经纬，专攻于开拓“虚实共生"的无垠创新疆域。团队精通于一
//                   站式精品化数字内容创作、艺术构思、技术创新及全程执行，将视觉
//                   听觉美学与最前端科技深度交融。
//                 </div>
//               </div>
//               <div className="flex justify-between items-center w-full h-42">
//                 <div>
//                   <div className="py-4">xx合作方</div>
//                   <div className="py-4">xx项目. 2023</div>
//                 </div>
//                 <div className="border border-white w-28 h-28"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
export default About;
