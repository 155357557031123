// App.js
import React, { useState, useEffect, useRef } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading.jsx";
import {
  useDetail,
  useLoading,
  useCardList,
  useLabel,
  useKeyword,
} from "./store.js";
import axios from "axios";
import Reveal from "./components/Reveal.jsx";
import Header from "./components/Header.jsx";
import Home from "./pages/Home";
import Scene from "./pages/HomeScene";
import About from "./pages/About";
import Item from "./pages/Item";
import Partner from "./pages/Partner";
import Cards from "./pages/Cards";
import Awards from "./pages/Awards";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
// import { buildPopulateUrl, buildComplexPopulateUrl } from "./utils/index.js";
// const cardPath = "/card";

// const CanvasTrail = () => {
//   const canvasRef = useRef(null);
//   const pointerRef = useRef({
//     x: 0.5 * window.innerWidth,
//     y: 0.5 * window.innerHeight,
//   });
//   const paramsRef = useRef({
//     pointsNumber: 40,
//     widthFactor: 0.3,
//     mouseThreshold: 0.6,
//     spring: 0.4,
//     friction: 0.5,
//   });
//   const trailRef = useRef([]);
//   const mouseMovedRef = useRef(false);

//   // Initialize trail positions
//   useEffect(() => {
//     const { pointsNumber } = paramsRef.current;
//     trailRef.current = new Array(pointsNumber).fill(null).map(() => ({
//       x: pointerRef.current.x,
//       y: pointerRef.current.y,
//       dx: 0,
//       dy: 0,
//     }));
//   }, []);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     const updateMousePosition = (x, y) => {
//       pointerRef.current.x = x;
//       pointerRef.current.y = y;
//     };

//     const handleMouseMove = (e) => {
//       mouseMovedRef.current = true;
//       updateMousePosition(e.pageX, e.pageY);
//     };

//     const handleTouchMove = (e) => {
//       mouseMovedRef.current = true;
//       updateMousePosition(e.targetTouches[0].pageX, e.targetTouches[0].pageY);
//     };

//     const setupCanvas = () => {
//       canvas.width = window.innerWidth;
//       canvas.height = window.innerHeight;
//     };

//     const update = (t) => {
//       const pointer = pointerRef.current;
//       const trail = trailRef.current;
//       const params = paramsRef.current;

//       if (!mouseMovedRef.current) {
//         pointer.x =
//           (0.5 + 0.3 * Math.cos(0.002 * t) * Math.sin(0.005 * t)) *
//           window.innerWidth;
//         pointer.y =
//           (0.5 + 0.2 * Math.cos(0.005 * t) + 0.1 * Math.cos(0.01 * t)) *
//           window.innerHeight;
//       }

//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//       ctx.strokeStyle = "white";
//       trail.forEach((p, pIdx) => {
//         const prev = pIdx === 0 ? pointer : trail[pIdx - 1];
//         const spring = pIdx === 0 ? 0.4 * params.spring : params.spring;
//         p.dx += (prev.x - p.x) * spring;
//         p.dy += (prev.y - p.y) * spring;
//         p.dx *= params.friction;
//         p.dy *= params.friction;
//         p.x += p.dx;
//         p.y += p.dy;
//       });

//       ctx.lineCap = "round";
//       ctx.beginPath();
//       ctx.moveTo(trail[0].x, trail[0].y);

//       for (let i = 1; i < trail.length - 1; i++) {
//         const xc = 0.5 * (trail[i].x + trail[i + 1].x);
//         const yc = 0.5 * (trail[i].y + trail[i + 1].y);
//         ctx.quadraticCurveTo(trail[i].x, trail[i].y, xc, yc);
//         ctx.lineWidth = params.widthFactor * (params.pointsNumber - i);
//         ctx.stroke();
//       }
//       ctx.lineTo(trail[trail.length - 1].x, trail[trail.length - 1].y);
//       ctx.stroke();

//       window.requestAnimationFrame(update);
//     };

//     setupCanvas();
//     update(0);

//     window.addEventListener("resize", setupCanvas);
//     window.addEventListener("mousemove", handleMouseMove);
//     window.addEventListener("touchmove", handleTouchMove);

//     return () => {
//       window.removeEventListener("resize", setupCanvas);
//       window.removeEventListener("mousemove", handleMouseMove);
//       window.removeEventListener("touchmove", handleTouchMove);
//     };
//   }, []);

//   return (
//     <canvas style={{ position: "fixed", top: 0, left: 0 }} ref={canvasRef} />
//   );
// };

// const ParticleCanvas = () => {
//   const canvasRef = useRef(null);
//   const particlesArrayRef = useRef([]);
//   const mouseRef = useRef({
//     x: null,
//     y: null,
//     radius: 70,
//   });

//   const particleSize = 1;
//   const particleSpacing = 2.4;

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     canvas.width = window.innerWidth;
//     canvas.height = window.innerHeight;

//     class Particle {
//       constructor(x, y) {
//         this.x = Math.random() * canvas.width;
//         this.y = Math.random() * canvas.height;
//         this.size = particleSize;
//         this.color = "#EB0029";
//         this.baseX = x;
//         this.baseY = y;
//         this.density = Math.random() * 30 + 1;
//       }

//       draw() {
//         ctx.fillStyle = this.color;
//         ctx.beginPath();
//         ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
//         ctx.closePath();
//         ctx.fill();
//       }

//       update() {
//         const mouse = mouseRef.current;
//         let dx = mouse.x - this.x;
//         let dy = mouse.y - this.y;
//         let distance = Math.sqrt(dx * dx + dy * dy);
//         let forceDirectionX = dx / distance;
//         let forceDirectionY = dy / distance;
//         let maxDistance = mouse.radius;
//         let force = (maxDistance - distance) / maxDistance;
//         let directionX = forceDirectionX * force * this.density;
//         let directionY = forceDirectionY * force * this.density;

//         if (distance < mouse.radius) {
//           this.x -= directionX;
//           this.y -= directionY;
//         } else {
//           if (this.x !== this.baseX) {
//             let dx = this.x - this.baseX;
//             this.x -= dx / 10;
//           }
//           if (this.y !== this.baseY) {
//             let dy = this.y - this.baseY;
//             this.y -= dy / 10;
//           }
//         }
//       }
//     }

//     const loadSVG = async (url) => {
//       const response = await fetch(url);
//       const text = await response.text();
//       const parser = new DOMParser();
//       const xmlDoc = parser.parseFromString(text, "image/svg+xml");
//       const paths = xmlDoc.getElementsByTagName("path");
//       return Array.from(paths).map((path) => path.getAttribute("d"));
//     };

//     const getParticlePositionsFromPathData = (pathData) => {
//       const positions = [];
//       const path = new Path2D(pathData);
//       const canvasWidth = canvas.width;
//       const canvasHeight = canvas.height;
//       let minX = canvasWidth,
//         maxX = 0,
//         minY = canvasHeight,
//         maxY = 0;

//       for (let y = 0; y < canvasHeight; y += particleSpacing) {
//         for (let x = 0; x < canvasWidth; x += particleSpacing) {
//           if (ctx.isPointInPath(path, x, y)) {
//             positions.push({ x, y });
//             if (x < minX) minX = x;
//             if (x > maxX) maxX = x;
//             if (y < minY) minY = y;
//             if (y > maxY) maxY = y;
//           }
//         }
//       }

//       return { positions, minX, maxX, minY, maxY };
//     };

//     const initParticles = async () => {
//       particlesArrayRef.current = [];
//       const svgPaths = await loadSVG(
//         window.filePath + "oxyz3.svg"
//         // "https://upload.wikimedia.org/wikipedia/fr/5/5b/Riot_Games_2022.svg"
//       );

//       let allPositions = [];
//       let minX = canvas.width,
//         maxX = 0,
//         minY = canvas.height,
//         maxY = 0;

//       svgPaths.forEach((pathData) => {
//         const {
//           positions,
//           minX: pathMinX,
//           maxX: pathMaxX,
//           minY: pathMinY,
//           maxY: pathMaxY,
//         } = getParticlePositionsFromPathData(pathData);
//         allPositions = allPositions.concat(positions);
//         if (pathMinX < minX) minX = pathMinX;
//         if (pathMaxX > maxX) maxX = pathMaxX;
//         if (pathMinY < minY) minY = pathMinY;
//         if (pathMaxY > maxY) maxY = pathMaxY;
//       });

//       const offsetX = (canvas.width - (maxX - minX)) / 2 - minX;
//       const offsetY = (canvas.height - (maxY - minY)) / 2 - minY;

//       allPositions = allPositions.map((pos) => ({
//         x: pos.x + offsetX,
//         y: pos.y + offsetY,
//       }));

//       allPositions.forEach((pos) => {
//         particlesArrayRef.current.push(new Particle(pos.x, pos.y));
//       });
//       // 计算并打印渲染大小
//       const renderedWidth = maxX - minX;
//       const renderedHeight = maxY - minY;
//       console.log(
//         `SVG 渲染大小：宽度 ${renderedWidth}px，高度 ${renderedHeight}px`
//       );
//       console.log(`画布大小：宽度 ${canvas.width}px，高度 ${canvas.height}px`);
//     };

//     const animateParticles = () => {
//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//       particlesArrayRef.current.forEach((particle) => {
//         particle.draw();
//         particle.update();
//       });
//       requestAnimationFrame(animateParticles);
//     };

//     const handleResize = () => {
//       canvas.width = window.innerWidth;
//       canvas.height = window.innerHeight;
//       initParticles();
//     };

//     initParticles();
//     animateParticles();

//     window.addEventListener("mousemove", (event) => {
//       mouseRef.current.x = event.x;
//       mouseRef.current.y = event.y;
//     });

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("mousemove", handleResize);
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return <canvas ref={canvasRef}></canvas>;
// };

export const App = () => {
  const setLoading = useLoading((state) => state.setLoading);
  const loading = useLoading((state) => state.loading);
  const setDetail = useDetail((state) => state.setDetail);
  const setLabelList = useLabel((state) => state.setLabelList);
  const labelList = useLabel((state) => state.labelList);
  const setKeywords = useKeyword((state) => state.setKeywordList);
  const setCardList = useCardList((state) => state.setCardList);
  const revealRef = useRef();
  const { t } = useTranslation();
  // const { nodes, materials } = useGLTF(window.filePath + "card.glb");
  // const { scene } = useGLTF(window.filePath + "cardBox.glb");
  // const [metalnessMap, roughnessMap] = useTexture([
  //   window.filePath + "metalness.png", // 金属贴图
  //   window.filePath + "roughness.png", // 粗糙度贴图
  // ]);
  const jump = (path, ags = "") => {
    revealRef.current.reveal(path, ags);
  };

  const fetchTranslations = async () => {
    console.log(1123123);
    try {
      // 通过接口请求获取翻译文件
      const timestamp = new Date().getTime();
      const { data } = await axios.get(`/wp-json/wp/v2/text?_=${timestamp}`);
      console.log(data, "data");
      const enStaticText = JSON.parse(data[0].acf.text_en);
      const zhStaticText = JSON.parse(data[0].acf.text_zh);
      // 添加获取到的翻译资源
      i18n.addResourceBundle("en", "translation", enStaticText, true, true);
      i18n.addResourceBundle("zh", "translation", zhStaticText, true, true);
    } catch (error) {
      console.error("Failed to load translations:", error);
    }
  };
  const load = async () => {
    setLoading(true);
    setTimeout(() => {
      // 15秒后强制加载完成
      setLoading(false);
    }, [15000]);
    const loadGLBFile = async (url) => {
      // Fetch the GLB file
      const response = await fetch(url, {
        cache: "force-cache", // Use the cached version if available
      });

      if (!response.ok) {
        console.error("Failed to load GLB file");
        return;
      }

      const blob = await response.blob();

      // Create a URL for the blob and store it for later use
      const blobUrl = URL.createObjectURL(blob);
      localStorage.setItem("cardGLB", blobUrl);
    };
    // 加载图片
    const loadImage = (url, index, total) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        if (index === total) {
          setTimeout(() => {
            console.log("加载完成");
            setLoading(false);
          }, [100]);
        }
      };
    };
    const getImageUrl = (imgArr, quality = "high") => {
      let url = imgArr.url;
      // 根据quality参数返回不同尺寸的图片URL
      try {
        switch (quality) {
          case "low":
            url = imgArr.sizes.thumbnail; // 低质量小图
            break;
          case "medium":
            url = imgArr.sizes.medium; // 中等质量
            break;
          case "high":
            url = imgArr.sizes.large; // 原图
            break;
          default:
            url = imgArr.sizes.large;
            break;
        }
      } catch (error) {
        console.log(error);
      }
      // 移除域名部分
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? url.replace("https://oxyz3.xyz", "")
          : url;
      return baseUrl;
    };
    // 提前加载图片
    const aheadLoadImage = (imgArr) => {
      // 检测当前网络设备情况
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      let quality = "high";

      // 根据网络类型和设备性能决定图片质量
      if (connection) {
        // 慢速网络加载低质量图片
        if (
          connection.effectiveType === "2g" ||
          connection.effectiveType === "slow-2g"
        ) {
          quality = "low";
        }
        // 3g网络加载中等质量图片
        else if (connection.effectiveType === "3g") {
          quality = "medium";
        }
      }

      // 移动设备默认加载中等质量图片
      if (window.isSmallDevice) {
        quality = "medium";
      }
      imgArr = imgArr.map((item) => getImageUrl(item.acf.map_photo, quality));
      imgArr.forEach((url, index) => {
        loadImage(url, index + 1, imgArr.length);
      });
      return imgArr;
    };
    const fetchData = async () => {
      try {
        const timestamp = new Date().getTime();
        let { data: homeCards } = await axios.get(
          `/wp-json/wp/v2/posts?categories=36&_=${timestamp}&per_page=100&_fields=id,acf&acf_format=standard`
        );
        let { data: labels } = await axios.get(
          `/wp-json/wp/v2/label?_=${timestamp}&per_page=100`
        );
        let { data: keywords } = await axios.get(
          `/wp-json/wp/v2/keyword?_=${timestamp}&per_page=100`
        );
        setKeywords(keywords.map((item) => ({ id: item.id, ...item.acf })));
        setLabelList(labels.map((item) => ({ id: item.id, ...item.acf })));

        // 提前加载图片
        const mapPhoto = aheadLoadImage(homeCards);
        // 处理卡片数据
        homeCards = homeCards.map((item, index) => ({
          ...item.acf,
          id: item.id,
          map_photo: mapPhoto[index],
        }));
        console.log(homeCards, "homeCards");
        setDetail(homeCards);
        let originalList = [
          {
            category: "金色",
            from: 0,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: homeCards.filter((item) => item.reflection === 128),
          },
          {
            category: "蓝色",
            from: (2 * Math.PI) / 3,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: homeCards.filter((item) => item.reflection === 46),
          },
          {
            category: "绿色",
            from: (4 * Math.PI) / 3,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: homeCards.filter((item) => item.reflection === 127),
          },
        ];
        // // 计算每个卡片的角度
        originalList.forEach((listItem, listIndex) => {
          listItem.list = listItem.list.map((item, index) => {
            // const fondLabelEN = detailEN.data.find(
            //   (detailItem) => detailItem.id === item.id
            // );
            // const fondLabelZH = detailZH.data.find(
            //   (detailItem) => detailItem.id === item.id
            // );
            return {
              ...item,
              angle:
                listItem.from + (index / listItem.list.length) * listItem.len,
              // label: [...fondLabelEN.label, ...fondLabelZH.label],
              // tag: [...fondLabelEN.tag, ...fondLabelZH.tag],
            };
          });
        });
        console.log(originalList, "originalList");
        // setLoading(false);
        setCardList(originalList);
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };
    await fetchTranslations();
    console.log(1);
    await loadGLBFile(window.filePath + "cardBox.glb");
    console.log(2);
    await loadGLBFile(window.filePath + "card.glb");
    console.log(3);
    await fetchData();
    console.log(4);
  };
  const IframeLoad = () => {
    console.log("IframeLoad");
    load();
  };

  return (
    <>
      {/* <ParticleCanvas></ParticleCanvas> */}
      {/* <CanvasTrail></CanvasTrail> */}
      {loading && (
        <div>
          <span className="fontThin">oxyz3</span>
          <span className="fontNormal">oxyz3</span>
          <span className="fontBold">oxyz3</span>
        </div>
      )}
      <Router>
        <Header t={t} jump={jump} />
        <Reveal ref={revealRef}></Reveal>
        <Cards></Cards>
        <Scene IframeLoad={IframeLoad}></Scene>
        <Loading />
        <Routes>
          <Route exact path="/" element={<Home t={t} jump={jump} />} />
          <Route path="/about" element={<About t={t} jump={jump} />} />
          <Route path="/item" element={<Home t={t} jump={jump} />} />
          <Route path="/detail" element={<Item t={t} jump={jump} />} />
          <Route path="/partner" element={<Partner t={t} jump={jump} />} />
          <Route path="/awards" element={<Awards t={t} jump={jump} />} />
        </Routes>
      </Router>
    </>
  );
};
