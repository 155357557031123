import React, { useEffect, useRef } from "react";
import { typewriter } from "../utils/index";
import { useTranslation } from "react-i18next";
export default function AnimationText({ text, classList, onClick }) {
  const textRef = useRef();
  const { i18n } = useTranslation();
  useEffect(() => {
    const element = textRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // 元素进入视口时开始动画
          typewriter(element, text, 30, 3, "ABCDEFGHIIIJKLNOPQRSTUVYZ");
          // 动画完成后取消观察
          observer.unobserve(element);
        }
      },
      {
        threshold: 1.0, // 元素完全进入视口时触发回调
      }
    );
    observer.observe(element);

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, [i18n.language]);
  return (
    <div className={classList} ref={textRef} onClick={onClick}>
      {text}
    </div>
  );
}
