import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./BackButton.scss";
const Button = ({ isBack, onClick, text }) => {
  const btnGlitchRef = useRef(null);
  const turbRef = useRef(null);
  const turbVal = { val: 0.000001 };
  const turbValX = { val: 0.000001 };

  useEffect(() => {
    const timeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 2,
      paused: true,
      onUpdate: () => {
        if (turbRef.current) {
          turbRef.current.setAttribute(
            "baseFrequency",
            `${turbVal.val} ${turbValX.val}`
          );
        }
      },
    });
    timeline
      .to(turbValX, { val: 0.5, duration: 0.1 })
      .to(turbVal, { val: 0.02, duration: 0.1 })
      .set(turbValX, { val: 0.000001 })
      .set(turbVal, { val: 0.000001 })
      .to(turbValX, { val: 0.4, duration: 0.2 }, 0.4)
      .to(turbVal, { val: 0.002, duration: 0.2 }, 0.4)
      .set(turbValX, { val: 0.000001 })
      .set(turbVal, { val: 0.000001 });
    const startGlitch = () => {
      timeline.play(0);
    };
    const stopGlitch = () => {
      timeline.pause();
    };
    const btnGlitchElement = btnGlitchRef.current;
    const handleMouseEnter = () => {
      btnGlitchElement.classList.add("btn-glitch-active-2");
      startGlitch();
    };
    const handleMouseLeave = () => {
      btnGlitchElement.classList.remove("btn-glitch-active-2");
      stopGlitch();
    };
    btnGlitchElement.addEventListener("mouseenter", handleMouseEnter);
    btnGlitchElement.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      btnGlitchElement.removeEventListener("mouseenter", handleMouseEnter);
      btnGlitchElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <>
      <svg
        style={{ position: "absolute", width: 0, height: 0 }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="svg-sprite"
      >
        <defs>
          <filter id="filter2">
            <feTurbulence
              ref={turbRef}
              type="fractalNoise"
              baseFrequency="0.000001 0.000001"
              numOctaves="1"
              result="warp"
              seed="1"
            ></feTurbulence>
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="30"
              in="SourceGraphic"
              in2="warp"
            ></feDisplacementMap>
          </filter>
        </defs>
      </svg>
      <div
        onClick={onClick}
        ref={btnGlitchRef}
        style={{
          transform: `${window.isSmallDevice ? "scale(0.6)" : "scale(1)"} ${
            isBack ? "rotate(180deg)" : ""
          }`,
        }}
        className="glitchBtn-2  btn-clear btn-light btn-glitch-2"
      >
        {text ? text : <i style={{ fontWeight: 7000 }}>&#xe643;</i>}
      </div>
    </>
  );
};

export default Button;
