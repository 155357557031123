import * as THREE from "three";
import {
  EffectComposer,
  Bloom,
  ChromaticAberration,
  Glitch,
} from "@react-three/postprocessing";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { TextureLoader } from "three";
import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree,
} from "@react-three/fiber";
import { gsap } from "gsap";
import {
  OrbitControls,
  ScrollControls,
  PerspectiveCamera,
  Billboard,
  useGLTF,
  MeshTransmissionMaterial,
  useScroll,
  Outlines,
} from "@react-three/drei";
import { easing, geometry } from "maath";
import {
  useLabel,
  useCardList,
  useActiveCard,
  useHoverCard,
} from "../../store";
extend(geometry);
extend({ AxesHelper: THREE.AxesHelper, GridHelper: THREE.GridHelper });
const cardPath = "/card";

const cardRotation = Math.PI / 12;

const Ele = ({ t, jump }) => {
  const orbitControlsRef = useRef();
  const [show, setShow] = useState(false);
  const cardList = useCardList((state) => state.cardList);
  const activeCard = useActiveCard((state) => state.activeCard);
  const setActiveCard = useActiveCard((state) => state.setActiveCard);
  const hoverCard = useHoverCard((state) => state.hoverCard);
  const setHoverCard = useHoverCard((state) => state.setHoverCard);
  const sloganRef = useRef();
  const location = useLocation();
  const setLabel = useLabel((state) => state.setLabel);
  const handleCanvasClick = () => {
    if (sloganRef.current) {
      sloganRef.current.style.opacity = "0";
      setTimeout(() => {}, 1000);
    }
  };
  // const handleUpdate = () => {
  //   if (orbitControlsRef.current) {
  //     const azimuthalAngle = orbitControlsRef.current.getAzimuthalAngle();
  //     console.log("Current Azimuthal Angle:", azimuthalAngle);
  //   }
  // };
  useEffect(() => {
    if (location.pathname === "/item") {
      setShow(true);
    } else {
      setLabel(false);
      setActiveCard(null);
      setHoverCard(null);
      setShow(false);
    }
  }, [location.pathname]);
  return (
    <Canvas
      dpr={[1, 3]}
      className="hide-scrollbar fixed select-none "
      style={{
        zIndex: location.pathname === "/item" ? "50" : "-2",
        height:
          location.pathname === "/item"
            ? window.isSmallDevice
              ? "calc(100svh - 48px)"
              : "calc(100svh - 66px)"
            : "0",
      }}
      onClick={handleCanvasClick}
    >
      <ScrollControls
        enabled={window.isSmallDevice && show}
        horizontal={true}
        pages={10}
        infinite
      >
        {window.isSmallDevice && (
          <PerspectiveCamera
            makeDefault
            position={[-2, 2, 10]} // 相机位置
          />
        )}
        <Effects show={show}></Effects>
        {/* <RedSphere position={[2, 2, 2]}></RedSphere>
      <RedSphere position={[1, 1, 1]}></RedSphere> */}
        {/* <axesHelper args={[50]} /> */}
        <CustomAxes
          visible={show}
          length={20}
          numConesPerAxis={20}
          coneSpacing={20}
        />
        {/* <gridHelper args={[10, 10]} /> */}
        {/* <color attach="background" args={["#000"]} /> */}
        {/* <Stage environment={null}> */}
        {/* {location.pathname === "/item" && (
          <Environment
            path={window.filePath}
            files="city.hdr"
            background
            blur={1}
          ></Environment>
        )} */}
        {/* </Stage> */}
        <CardBox visible={show}></CardBox>
        {/* <SpotLight
        position={[0, 5, 0]} // 聚光灯的位置
        angle={1.5} // 聚光灯的角度
        penumbra={1.5} // 聚光灯的边缘模糊程度
        intensity={1} // 光的强度
        // castShadow // 是否投射阴影
      /> */}
        <ambientLight
          visible={show}
          intensity={2.4}
          color={"#fff"}
        ></ambientLight>
        <pointLight visible={show} position={[0, 5, 0]} intensity={0.1} />
        <pointLight
          visible={show}
          position={[2, 2, 5]}
          intensity={0.1}
          color={"red"}
        />
        <pointLight
          visible={show}
          position={[1, 2, 1]}
          intensity={0.1}
          color={"blue"}
        />
        {/* <directionalLight
        position={[0, 0, 0]}
        intensity={1}
        target={new THREE.Object3D([0, 0, 0])}
      /> */}
        {/* <DirectionalLightWithHelper /> */}
        {/* <Mouses activeCard={activeCard}></Mouses> */}
        {!window.isSmallDevice && show && (
          <OrbitControls
            ref={orbitControlsRef}
            enablePan={false}
            minDistance={8}
            maxDistance={window.isSmallDevice ? 16 : 8}
            target={[0, 0.25, 0]}
            maxPolarAngle={Math.PI / 2.7}
            minPolarAngle={Math.PI / 2.7}
          />
        )}
        {/* <OrbitControls /> */}
        <Scene
          onActive={setActiveCard}
          controls={orbitControlsRef}
          activeCard={activeCard}
          hoverCard={hoverCard}
          onHover={setHoverCard}
          position={[0, 1.5, 0]}
          visible={show}
          list={cardList}
          cardScale={[0.12, 0.12, 0.12]}
          t={t}
        />
      </ScrollControls>
      {/* <CardBox jump={jump} visible={visible} /> */}
    </Canvas>
  );
};
// object.visible = false（也适用于灯光）或material.opacity = 0。您可以设置light.intensity = 0为禁用灯光而不会导致着色器重新编译
const Scene = React.memo(
  ({
    onActive,
    activeCard,
    hoverCard,
    onHover,
    position,
    list,
    visible,
    cardScale,
    controls,
  }) => {
    const refs = useRef(null);
    const hoverFlag = useRef(false);
    const outFlag = useRef(false);
    const activeCardRef = useRef(null);
    const hoverCardRef = useRef(null);
    const hoverOutCardRef = useRef(null);
    const radius = window.isSmallDevice ? 3.6 : 5.25;
    console.log("触发渲染");
    // }, []);
    const scroll = useScroll();
    const { nodes, materials } = useGLTF(window.filePath + "card.glb");
    // 激活的标签
    const label = useLabel((state) => state.label);
    const [isClick, setIsClick] = useState(false);
    const imgArr = useMemo(() => {
      return list
        .map((item) => item.list)
        .flat()
        .sort((a, b) => a.id - b.id);
    }, [list]);

    const imgMapImgArr = useMemo(() => {
      return imgArr.map((item) => item.map_photo);
    }, [imgArr]);
    // 使用 useLoader 加载纹理
    const imgMaterials = useLoader(TextureLoader, imgMapImgArr);

    // 使用 useMemo 处理加载后的材质
    const { imgFrontMaterial, p } = useMemo(() => {
      const imgFrontID = imgArr.map((item) => item.id);
      let imgFrontMaterial = imgMaterials.map((item, index) => ({
        material: item,
        id: imgFrontID[index],
      }));

      return {
        imgFrontMaterial,
        p: new THREE.Vector3(),
      };
    }, [imgMaterials, imgArr]);
    // 事件处理
    const handleCardClick = (event, item, ref, angle, position, rotation) => {
      if (!visible) return;
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      activeCardRef.current = ref.current;
      activeCardRef.current.attribute = {
        angle,
        position,
        rotation,
        i: item.id,
      };
      setIsClick(true);
      console.log(item, "item");
      onActive({ ...item, position });
    };

    const handleHover = (event, i, ref) => {
      if (!visible) return;
      if (!window.isSmallDevice && hoverFlag.current === false) {
        hoverFlag.current = true;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        hoverCardRef.current = ref.current;
        setTimeout(() => {
          hoverFlag.current = false;
        }, [100]);
        onHover(i);
      }
    };
    const handleHoverOut = (event, i, ref) => {
      if (!visible) return;
      if (!window.isSmallDevice && outFlag.current === false) {
        outFlag.current = true;
        setTimeout(() => {
          outFlag.current = false;
        }, [100]);
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        hoverOutCardRef.current = ref.current;
        onHover(null);
      }
    };
    // 动画
    useFrame((state, delta) => {
      state.events.update();
      refs.current.rotation.y = -scroll.offset * (Math.PI * 2);
      if (visible) {
        // 场景可见
        if (isClick) {
          // 是否点击
          if (activeCard !== null) {
            // 是否存在激活的卡片
            if (window.isSmallDevice) {
              p.set(-3, -1, -11);
            } else {
              p.set(-3, -1, -5);
            }
            p.applyMatrix4(state.camera.matrixWorld);
            easing.damp3(activeCardRef.current.position, p, 0.2, delta);
            easing.damp3(
              activeCardRef.current.rotation,
              [
                0,
                0.2 - (Math.PI / 5 + activeCardRef.current.attribute.angle),
                0,
              ],
              0.4,
              delta
            );
          } else {
            easing.damp3(
              activeCardRef.current.position,
              activeCardRef.current.attribute.position,
              0.2,
              delta
            );
            easing.damp3(
              activeCardRef.current.rotation,
              [0, cardRotation + activeCardRef.current.attribute.angle, 0],
              0.2,
              delta
            );
          }
        } else {
          // 鼠标未点击
        }
        // 鼠标悬停
        for (let i = 0; i < list.length; i++) {
          easing.damp3(
            refs.current.children[i].position,
            [0, 0, 0],
            0.2,
            delta
          );
          for (let j = 0; j < list[i].list.length; j++) {
            const item = list[i].list[j];
            // console.log(item);
            // console.log(item.labels.some((labelObj) => labelObj.id === 1));
            if (item.labels.some((labelObj) => labelObj === label)) {
              easing.damp3(
                refs.current.children[i].children[j + 1].scale,
                cardScale,
                0,
                delta
              );
            } else {
              if (label !== false) {
                easing.damp3(
                  refs.current.children[i].children[j + 1].scale,
                  [0, 0, 0],
                  0,
                  delta
                );
              } else {
                if (hoverCard === item.id && hoverCardRef.current) {
                  // console.log(controls.current.getAzimuthalAngle());
                  // console.log(state.camera.position.x);
                  // lookAt 可以做到
                  // hoverCardRef.current.lookAt(1, 1, 1);
                  // getAzimuthalAngle 获取当前的转动角度
                  easing.damp3(
                    hoverCardRef.current.rotation,
                    [0, -1.6 + controls.current.getAzimuthalAngle(), 0],
                    0.2,
                    delta
                  );
                  // const cameraPosition = state.camera.position;
                  // ro.set(-3, -1, -11);
                  // ro.applyMatrix4(state.camera.matrixWorld);
                  // // 鼠标悬停情况
                  easing.damp3(
                    hoverCardRef.current.scale,
                    [
                      cardScale[0] * 1.2,
                      cardScale[1] * 1.2,
                      cardScale[2] * 1.2,
                    ],
                    0.2,
                    delta
                  );
                  // easing.damp3(
                  //   hoverCardRef.current.rotation,
                  //   [0, -1.6, 0],
                  //   0.2,
                  //   delta
                  // );
                  // const cardPosition = hoverCardRef.current.position;
                  // 计算方向向量
                  // const direction = cardPosition
                  //   .clone()
                  //   .sub(cameraPosition)
                  //   .normalize();
                  // easing.damp3(
                  //   hoverCardRef.current.rotation,
                  //   [0, hoverCardRef.current.attribute.angle / 2, 0],
                  //   0.2,
                  //   delta
                  // );
                  // easing.damp3(
                  //   hoverCardRef.current.position,
                  //   [
                  //     Math.sin(item.angle) * radius,
                  //     0.2,
                  //     Math.cos(item.angle) * radius,
                  //   ],
                  //   0.2,
                  //   delta
                  // );
                } else {
                  // 卡片默认情况下
                  easing.damp3(
                    refs.current.children[i].children[j + 1].position,
                    [
                      Math.sin(item.angle) * radius,
                      0,
                      Math.cos(item.angle) * radius,
                    ],
                    0.2,
                    delta
                  );
                  easing.damp3(
                    refs.current.children[i].children[j + 1].rotation,
                    [0, cardRotation + item.angle - 0.8, 0],
                    0.2,
                    delta
                  );
                  easing.damp3(
                    refs.current.children[i].children[j + 1].scale,
                    cardScale,
                    0.1,
                    delta
                  );
                }
              }
            }
          }
        }
        if (hoverOutCardRef.current) {
          // 存在鼠标离开的对象
          // easing.damp3(hoverOutCardRef.current.rotation, [0, 0, 0], 0.2, delta);
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          easing.damp3(
            refs.current.children[i].position,
            [0, -2, 0],
            0.2,
            delta
          );
          // easing.damp3(refs.current.children[i].scale, [0, 0, 0], 0.1, delta);
          for (let j = 0; j < list[i].list.length; j++) {
            easing.damp3(
              refs.current.children[i].children[j + 1].position,
              [0, 0, 0],
              0.2,
              delta
            );
            easing.damp3(
              refs.current.children[i].children[j + 1].rotation,
              [0, 0, 0],
              0.2,
              delta
            );
            easing.damp3(
              refs.current.children[i].children[j + 1].scale,
              [0, 0, 0],
              0.2,
              delta
            );
          }
        }
      }
    });

    return (
      <mesh ref={refs} position={position} visible={visible}>
        {list.map((listItem, listIndex) => {
          const amount = Math.round(listItem.len * 11);
          const textPosition =
            listItem.from + (amount / 2 / amount) * listItem.len;
          return (
            <mesh key={listIndex} visible={listItem.visible}>
              <group
                visible={false}
                position={[
                  Math.sin(textPosition) * radius * 1.4,
                  2,
                  Math.cos(textPosition) * radius * 1.4,
                ]}
              >
                {/* <Text fontSize={0.3} anchorX="center" color="rgb(255, 255, 255)">
                {[t("金色"), t("蓝色"), t("绿色")][listIndex]}
              </Text> */}
              </group>
              {listItem.list.map((item, index) => {
                let ref = React.createRef();
                return (
                  <group
                    ref={ref}
                    onClick={(event) => {
                      handleCardClick(
                        event,
                        item,
                        ref,
                        item.angle,
                        [
                          Math.sin(item.angle) * radius,
                          0,
                          Math.cos(item.angle) * radius,
                        ],
                        [0, cardRotation + item.angle, 0]
                      );
                    }}
                    key={index}
                    onPointerOver={(e) => handleHover(e, item.id, ref)}
                    onPointerOut={(e) => {
                      handleHoverOut(e, item.id, ref);
                    }}
                    position={[
                      Math.sin(item.angle) * radius,
                      0,
                      Math.cos(item.angle) * radius,
                    ]}
                    rotation={[0, cardRotation + item.angle, 0]}
                    angle={item.angle}
                    dispose={null}
                  >
                    <mesh position={[0, 6, 0]}>
                      <boxGeometry args={[8, 14, 11]} />
                      <meshBasicMaterial visible={false} />
                    </mesh>
                    <mesh
                      geometry={nodes.pPlane1.geometry}
                      material={materials.lambert2}
                    >
                      {/* <Html>{cardRotation + item.angle - 0.8}</Html> */}
                      <Outlines
                        thickness={hoverCard === item.id ? 3 : 0}
                        color={"#38bdf8"}
                      />

                      {imgFrontMaterial.find((i) => i.id === item.id)
                        .material && (
                        <meshStandardMaterial
                          toneMapped={false}
                          opacity={visible ? 1 : 0}
                          map={
                            imgFrontMaterial.find((i) => i.id === item.id)
                              .material
                          }
                        />
                      )}
                    </mesh>
                  </group>
                );
              })}
            </mesh>
          );
        })}
      </mesh>
    );
  }
);

function Effects({ show }) {
  const label = useLabel((state) => state.label);
  const [glitch, setGlitch] = useState(false);
  // const { camera } = useThree();
  // const moveCamera = () => {
  //   gsap.to(camera.position, {
  //     x: 10,
  //     duration: 0.2,
  //     onUpdate: () => {},
  //   });

  //   // gsap.to(orbitControlsRef.current.target, {
  //   //   x: 1,
  //   //   y: 1,
  //   //   z: 1,
  //   //   duration: 2,
  //   //   onUpdate: () => {
  //   //     orbitControlsRef.current.update();
  //   //   },
  //   // });
  // };
  // 激活的标签
  useEffect(() => {
    setGlitch(true);
    setTimeout(() => {
      setGlitch(false);
    }, [200]);
  }, [label, show]);
  return (
    <EffectComposer enabled={show} multisampling={0} disableNormalPass={true}>
      <Bloom
        luminanceThreshold={0}
        luminanceSmoothing={1}
        height={300}
        opacity={1}
      />
      <Glitch
        active={glitch}
        strength={[0.5, 0]}
        chromaticAberrationOffset={[0.01, 0.01]}
      ></Glitch>
      <ChromaticAberration
        offset={glitch ? [0.01, 0.01] : [0, 0]} // color offset
      />
    </EffectComposer>
  );
}

function Mouses({ activeCard }) {
  const mouseRef = useRef();
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [
      0,
      0,
      activeCard !== null ? 0 : 0,
    ]);
    if (activeCard) {
      easing.damp3(
        mouseRef.current.position,
        [
          (pointer.x * width) / 8.5,
          (pointer.y * height) / 8.5,
          activeCard !== null ? 6.3 : 6.3,
        ],
        activeCard !== null ? 0 : 0,
        delta
      );
    }
    easing.damp3(
      mouseRef.current.scale,
      activeCard !== null ? 6 : 0,
      activeCard !== null ? 0.4 : 0.2,
      delta
    );
    easing.dampC(
      mouseRef.current.material.color,
      activeCard !== null ? "#e8e8e8" : "#ccc",
      // activeCard !== null ? "red" : "red",
      0.1,
      delta
    );
  });
  return (
    <Billboard>
      <mesh ref={mouseRef}>
        <circleGeometry args={[1, 64, 64]} />
        <MeshTransmissionMaterial
          samples={16}
          resolution={512}
          anisotropicBlur={0.1}
          thickness={0.1}
          ior={1.15}
          roughness={0.4}
          toneMapped={true}
        />
      </mesh>
    </Billboard>
  );
}

function CardBox({ visible, jump }) {
  const scroll = useScroll();
  const cardBoxRef = useRef();
  const setLabel = useLabel((state) => state.setLabel);
  useFrame(({ viewport, camera, pointer, clock }, delta) => {
    cardBoxRef.current.rotation.y = -scroll.offset * (Math.PI * 2);
    const t = clock.getElapsedTime();
    const { width } = viewport.getCurrentViewport(camera, [0, 0, 0]);
    cardBoxRef.current.rotation.x = Math.cos(t / 1) / 8;
    // easing.damp3(
    //   cardBoxRef.current.position,
    //   [(pointer.x * width) / 4, -4, 0],
    //   1,
    //   delta
    // );
    // // cardBoxRef.current.position.x = pointer.x * width;
    // if (!visible) {
    //   easing.damp3(cardBoxRef.current.scale, [0, 0, 0], 0.2, delta);
    // } else {
    //   easing.damp3(cardBoxRef.current.scale, [14, 14, 14], 0.2, delta);
    // }
  });
  const { scene } = useGLTF(window.filePath + "cardBox.glb");
  // const [metalnessMap, roughnessMap] = useTexture([
  //   window.filePath + "metalness.png", // 金属贴图
  //   window.filePath + "roughness.png", // 粗糙度贴图
  // ]);

  return (
    <group
      visible={visible}
      onClick={() => {
        setLabel(false);
        // jump("item");
      }}
    >
      <primitive
        ref={cardBoxRef}
        scale={window.isSmallDevice ? [4, 4, 4] : [7, 7, 7]}
        position={window.isSmallDevice ? [0, 1.6, 0] : [0, 1, 0]}
        object={scene}
      >
        <Outlines thickness={10} color={"#38bdf8"} />
      </primitive>
    </group>
  );
}

function CustomAxes({ length, numConesPerAxis, coneSpacing, visible }) {
  const axesRef = useRef();
  const scroll = useScroll();
  useEffect(() => {
    const axes = axesRef.current;

    const axisRadius = 0.02; // 轴的半径
    const axisHeight = length; // 轴的高度
    const xMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 });
    const yMaterial = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
    const zMaterial = new THREE.MeshStandardMaterial({ color: 0x0000ff });

    const xAxis = new THREE.Mesh(
      new THREE.CylinderGeometry(axisRadius, axisRadius, axisHeight, 32),
      xMaterial
    );
    xAxis.rotation.z = -Math.PI / 2;
    xAxis.position.x = axisHeight / 2;
    axes.add(xAxis);
    const sphereGeometry = new THREE.SphereGeometry(0.3, 32, 32);
    const sphereMaterial = new THREE.MeshStandardMaterial({
      color: 0xffff00,
    });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.position.set(0, 0, 0);
    axes.add(sphere);
    const yAxis = new THREE.Mesh(
      new THREE.CylinderGeometry(axisRadius, axisRadius, axisHeight, 32),
      yMaterial
    );
    yAxis.position.y = axisHeight / 2;
    axes.add(yAxis);

    const zAxis = new THREE.Mesh(
      new THREE.CylinderGeometry(axisRadius, axisRadius, axisHeight, 32),
      zMaterial
    );
    zAxis.rotation.x = Math.PI / 2;
    zAxis.position.z = axisHeight / 2;
    axes.add(zAxis);

    // Function to create and animate multiple cones along an axis
    function addMovingCones(material, positionProperty, rotation) {
      for (let i = 0; i < numConesPerAxis; i++) {
        const coneGeometry = new THREE.ConeGeometry(0.1, 0.5, 12);
        const cone = new THREE.Mesh(coneGeometry, material);
        cone.rotation.set(...rotation);
        axes.add(cone);

        // Initial position
        cone.position.set(0, 0, 0);

        // Function to animate cone
        function animateCone() {
          gsap.to(cone.position, {
            [positionProperty]: axisHeight,
            duration: 20, // Duration of the animation
            ease: "linear", // Linear easing for constant speed
            delay: (i * coneSpacing) / axisHeight, // Stagger the animation start
            onComplete: () => {
              cone.position.set(0, 0, 0);
              animateCone(); // Restart the animation
            },
          });
        }

        // Start the animation
        animateCone();
      }
    }

    // Add cones moving along each axis with proper rotation
    addMovingCones(xMaterial, "x", [0, 0, -Math.PI / 2]);
    addMovingCones(yMaterial, "y", [0, 0, 0]);
    addMovingCones(zMaterial, "z", [Math.PI / 2, 0, 0]);

    // Clean up on component unmount
    return () => {
      while (axes.children.length > 0) {
        const child = axes.children[0];
        axes.remove(child);
        if (child.geometry) child.geometry.dispose();
        if (child.material) child.material.dispose();
      }
    };
  }, [length, numConesPerAxis, coneSpacing]);
  useFrame((state, delta) => {
    axesRef.current.rotation.y = -scroll.offset * (Math.PI * 2);
  });
  return <group visible={visible} ref={axesRef} />;
}

// function RadioWaves() {
//   const ringsRef = useRef([]);

//   // 创建多个圆环
//   const createRings = () => {
//     const rings = [];
//     for (let i = 0; i < 5; i++) {
//       rings.push(
//         <mesh
//           key={i}
//           ref={(el) => (ringsRef.current[i] = el)}
//           position={[0, 0, 0]}
//         >
//           <torusGeometry args={[1 + i * 0.5, 0.1, 16, 100]} />
//           <meshBasicMaterial color={"white"} opacity={0.7} transparent />
//         </mesh>
//       );
//     }
//     return rings;
//   };

//   useFrame(() => {
//     ringsRef.current.forEach((ring, i) => {
//       gsap.to(ring.scale, {
//         x: 5,
//         y: 5,
//         z: 5,
//         duration: 3,
//         repeat: -1,
//         ease: "power1.inOut",
//         delay: i * 0.5,
//         yoyo: true,
//       });
//       gsap.to(ring.material, {
//         opacity: 0,
//         duration: 3,
//         repeat: -1,
//         ease: "power1.inOut",
//         delay: i * 0.5,
//         yoyo: true,
//       });
//     });
//   });

//   return <group>{createRings()}</group>;
// }

export default Ele;
