import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
export default function AnimationLine({ animation, classList }) {
  const elementRef = useRef();
  useEffect(() => {
    const element = elementRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          gsap.to(element, animation);
          observer.unobserve(element);
        }
      },
      {
        threshold: 0.1,
      }
    );
    observer.observe(element);
    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div
      ref={elementRef}
      style={{
        zIndex: 50,
      }}
      className={classList}
    ></div>
  );
}
