import React, {
  useRef,
  useLayoutEffect,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import "./Reveal.scss";
const Reveal = React.forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const overlayPathRef = useRef(null);
  const pageSwitchTimeline = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  //   const [path, setPath] = useState(null);
  let targetPath = useRef({
    ags: null,
    path: location.pathname,
  });
  const switchPages = () => {
    const params = new URLSearchParams(targetPath.current.ags).toString();
    const pathWithParams = `${targetPath.current.path}?${params}`;
    console.log(params, pathWithParams);
    navigate(pathWithParams);
  };
  useLayoutEffect(() => {
    const paths = {
      step1: {
        unfilled: "M 0 0 h 0 c 0 50 0 50 0 100 H 0 V 0 Z",
        inBetween: "M 0 0 h 33 c -30 54 113 65 0 100 H 0 V 0 Z",
        filled: "M 0 0 h 100 c 0 50 0 50 0 100 H 0 V 0 Z",
      },
      step2: {
        filled: "M 100 0 H 0 c 0 50 0 50 0 100 h 100 V 50 Z",
        inBetween: "M 100 0 H 50 c 28 43 4 81 0 100 h 50 V 0 Z",
        unfilled: "M 100 0 H 100 c 0 50 0 50 0 100 h 0 V 0 Z",
      },
    };

    pageSwitchTimeline.current = gsap
      .timeline({
        paused: true,
        onComplete: () => setIsAnimating(false),
      })
      .set(overlayPathRef.current, {
        attr: { d: paths.step1.unfilled },
      })
      .to(
        overlayPathRef.current,
        {
          duration: 0.8,
          ease: "power3.in",
          attr: { d: paths.step1.inBetween },
        },
        0
      )
      .to(overlayPathRef.current, {
        duration: 0.2,
        ease: "power1",
        attr: { d: paths.step1.filled },
        onComplete: () => switchPages(),
      })
      .set(overlayPathRef.current, {
        attr: { d: paths.step2.filled },
      })
      .to(overlayPathRef.current, {
        duration: 0.15,
        ease: "sine.in",
        attr: { d: paths.step2.inBetween },
      })
      .to(overlayPathRef.current, {
        duration: 1,
        ease: "power4",
        attr: { d: paths.step2.unfilled },
      });
  });

  const reveal = (path, ags) => {
    console.log(path, ags);
    if (isAnimating) return;
    if (targetPath.current.path === path && targetPath.current.ags === ags)
      return;
    if (
      ["/", "item", "/item"].includes(path) &&
      ["/", "item", "/item"].includes(targetPath.current.path)
    ) {
      targetPath.current.path = path;
      targetPath.current.ags = ags;
      switchPages();
    } else {
      setIsAnimating(true);
      targetPath.current.path = path;
      targetPath.current.ags = ags;
      pageSwitchTimeline.current.play(0);
    }
  };
  useImperativeHandle(ref, () => ({
    reveal,
  }));

  return (
    <div>
      <svg
        className="overlay"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          className="overlay__path"
          ref={overlayPathRef}
          fill="white"
          vectorEffect="non-scaling-stroke"
          d="M 0 0 h 0 c 0 50 0 50 0 100 H 0 V 0 Z"
        />
      </svg>
    </div>
  );
});

export default Reveal;
